import SDKForm from './../sdk/sdkForm';
const userForm = {
	...SDKForm,
	'name': {is_required: true, label: "Name", icon:'person'},
	'phoneNumber': {is_required: true, label: "Phone Number", icon: 'phone'},
	'email': { 
		label: "Email", 
		is_required: true, 
		error: '', 
		validation: function(val){
			this.error = '';
				if(!(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test((val||"").toLowerCase()))
			this.error = 'Invalid Email';
		}
	},
	'secondaryEmail':{
		label: "Secondary Email", 
		error: '', 
		value: '',
		validation: function(val){
			this.error = '';
				if(!(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test((val||"").toLowerCase()))
			this.error = 'Invalid Email';
		}
	},
	'notificationType': {label: 'Notification Setting', value: [0]},
	'userRole': {label: "User Role", value: 0}
}

export default userForm;