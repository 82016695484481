import Resource from './resource';

export default class UserResource extends Resource{
	constructor(){
		super('users');
	}

	async studentSummary(email){
		let studentResource = new Resource("students");
		let result = await studentResource.get(null, {email})
		return result;
	}
}