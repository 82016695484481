import React, {useEffect} from 'react';

import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Toolbar from '@material-ui/core/Toolbar';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import "./utilityBar.css";

let interval = null;

const UtilityBar = (props)=>{
	let {selected, searchFields, children, onCancelSelected, hasSearchBar, onSearch} = props;
	let [searchValue, setSearchValue] = React.useState("");
	let [searchField, setSearchField] = React.useState((searchFields && searchFields.length)?searchFields[0].id:null);
	let [filter, setFilter] = React.useState(searchFields.reduce((res,item)=>{
		if(item.type=='boolean') res[item.id] = item.default; 
		return res;
	},{}));
	const onSearchFieldSelected = (evt)=>{
		for(let f of searchFields)
			if(f.id === evt.target.value && f.type=='boolean') return;
		setSearchField(evt.target.value);
		if(searchValue){
			setSearchValue("");
			onSearch([{id: evt.target.value, value: ""}]);
		}
	}

	const handleOnBooleanChange = (id)=>{
		filter[id] = !filter[id];
		setFilter({...filter});
		const t = Object.entries(filter).map(item=>({id: item[0], value: item[1]}));
		t.push({id: searchField, value: searchValue});
		onSearch(t);
	}

	const onSearchChanged = (evt)=>{
		searchValue = evt.target.value;
		setSearchValue(searchValue);
		if(interval){
			clearInterval(interval);
			interval = null;
		}
		interval = setInterval(()=>{
				clearInterval(interval);
				interval = null;
				if(searchValue){
					searchValue = searchValue.toLowerCase();
					const field = searchFields.filter(item=>item.id==searchField);
					if(field.length && field[0].searchMaps){
						const maps = field[0].searchMaps.filter(item=>item.searchPattern.indexOf(searchValue)>-1).map(item=>item.value);
						searchValue = maps.length?maps.join(","):"";
					}
				}
				onSearch([{id: searchField, value: searchValue}]);
		},1000);
		
	}

	return (
		<Toolbar className="row-flex center-flex utility-bar" style={{minHeight: "48px"}}>
			<React.Fragment>
				{hasSearchBar? 
					<div style={{maxWidth:"600px", flexWrap: "nowrap"}} className="search row-flex center-flex">
						<input className="free-space" value={searchValue} onChange={onSearchChanged}/>
						<span style={{margin: "0 10px", height: "20px", borderRight:"1.5px solid rgba(0,0,0,.3)"}}></span>
						<React.Fragment>
							{(searchFields && searchFields.length)?(
								<Select
								  style={{fontSize: "12px", paddingLeft:"10px", minWidth:"120px"}}
								  className="search-fields"
						          labelId="search-fields"
						          id="search-fields"
						          value={searchField}
						          onChange={onSearchFieldSelected}
						        >
						        		{searchFields.map((item)=>(
						        			(item.type=='break')?(<hr key={item.id || new Date()}/>):(item.type!='boolean')?(<MenuItem className="utility-bar-menu" value={item.id} key={item.id||new Date()}><span className="MuiCheckbox-root"></span>{item.label}</MenuItem>):(<MenuItem className="utility-bar-menu" value={item.id} key={item.id || new Date()}><Checkbox id={item.id} checked={filter[item.id]}  onChange={evt=>handleOnBooleanChange(item.id, evt.target.value)}></Checkbox>{item.label}</MenuItem>)
						        		))}
						        </Select>

							):<React.Fragment/>}
						</React.Fragment>
						<Icon style={{color:"rgba(0,0,0,.3)", fill: "rgba(0,0,0,.3)"}}>search</Icon>
					</div>
					:<React.Fragment/>}
			</React.Fragment>
			<div className="free-space"/>
			<div className="row-flex center-flex" style={{flexWrap:"nowrap", width:"initial"}}>
				<React.Fragment>{selected?(
					<React.Fragment>
						<IconButton aria-label="expand row" size="small" onClick={onCancelSelected}>
							<Icon>clear</Icon>
						</IconButton>
						<span style={{fontWeight:700, fontSize:"12px"}}>{selected + " selected"} <span style={{margin: "0 10px", height: "20px", borderRight:"1.5px solid black"}}></span></span>
					</React.Fragment>
					):<React.Fragment/>}
				</React.Fragment>
				<React.Fragment>
					{children}
				</React.Fragment>
			</div>
		</Toolbar>

	);
}

export default UtilityBar;
