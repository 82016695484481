import React, {useState} from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch,
  useParams,
  useLocation
} from "react-router-dom";

import Users from './../users/users';
import Students from './../students/students';
import Sessions from './../sessions/sessions';
import Profile from './../profile/profile';
import UpdatePassword from './../resetPassword/updatePassword';
import ForgetPassword from './../resetPassword/forgetPassword';

const routeItems = [
  { path: '/users', component: Users },
  { path: '/students', component: Students },
  { path: '/profile', component: Profile },
  { path: '/sessions', component: Sessions },
  { path: '/updatePassword', component: UpdatePassword },
  { path: '/forgetPassword', component: ForgetPassword },
  { path: '/', component: Profile }
]
  

export default function AppRoutes() {
  return (
      <Switch>
        { routeItems.map(item=>(
          <Route path={item.path} component= {item.component} key={item.path}/>
        ))}
      </Switch>
  );
}