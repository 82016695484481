import SDKForm from './../sdk/sdkForm';
const studentSessionForm = {
	...SDKForm,
	'course': {label: "Course", is_required: true, default:"Scratch"},
	'sessionName': {label: "Session Name", is_required: true, default: 1},
	'sessionOverallRating': {label: "Did the students understand everything that was taught in the session?", is_required: true},
	'sessionNote': {label: "Did anything noteworthy happen? For example if the student build a project on their own, or did extremely well in class, or had a difficult time understanding, mention the incident."},
	'studentHomeworkStatus': {label: "Did the student complete homework? (only applicable if homework was given last session)", is_required: true},
	'studentHomeworkFeedback': {label: "Did the student find the homework pdf helpful?", is_required: true},
	'sessionMaterialFeedback': {label: "Was the teaching document for session helpful?", is_required: true},
	'sessionMaterialNote': {label: "Please elaborate on what happened if your answer was option 2 or 3"},
	'sessionTimeCompletions': {label: "How much time did it take you to teach the session?", is_required: true},
	'sessionDifficultyFeedback': {label: "How difficult are the students finding the session?", is_required: true},
	'sessionDifficultyNote': {label: "Please elaborate which concepts felt difficult if your answer was option 4"},
	'homeworkLink': {label: "Homework Details"},
	'instructorEmail': {label: "Instructor", is_required: true},
	"date": {label: "Date", is_required: true, default: new Date()},
	'emailNotification': {label: "Send feedback to parents and students", default: true},
}

export default studentSessionForm;