import React, {useEffect} from 'react';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import TableRow from '@material-ui/core/TableRow';
import VisibilityIcon from '@material-ui/icons/Visibility';

import {data} from './config';

const {sessionHeaders} =  data;


const SessionRow = (props)=>{
    return (
    	<React.Fragment>
    		<TableRow  key={props.index}>
		        <TableCell>
			        <IconButton className="table-action" aria-label="expand row" size="small" onClick={()=>props.onAction('viewSessionDetail', props.row)}>
						<VisibilityIcon/>
					</IconButton>
		        </TableCell>
		        <TableCell>{props.row.course || ""}</TableCell>
		        <TableCell>{props.row.date || ""}</TableCell>
		        <TableCell>{props.row.sessionName || ""}</TableCell>
		        <TableCell>{props.row.students.filter(item=>(item._id==props.metaData.studentId && item.absent)).length?"Absent":"Attended"}</TableCell>
		    </TableRow>	    		
		</React.Fragment>
    );
}

export default SessionRow;
