import React, {useContext} from 'react';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@mui/material/Divider';
import PhoneInput from 'react-phone-input-2';
import userForm from './../forms/user';
import { Button } from '@material-ui/core';
import Resource from './../resources/resource';
import { Broadcast } from './../sdk/broadcast';
import Edit from '@material-ui/icons/Edit';
import UpdatePassword from './../resetPassword/updatePassword';
import UpdateEmail from './updateEmail';

let formData = {...userForm};
delete formData.email;
delete formData.userRole;
delete formData.secondaryEmail;
delete formData.notificationType;
function PersonalSummary({user}){
	const {broadcast} = React.useContext(Broadcast);
	const [form, setForm] = React.useState({...formData});
	const[isEdit, setEdit] = React.useState(-1);

	const handleChange = (id, value)=>{
		form.onChange(id, value);
		setForm({...form});
	}
	
	const onCancel = ()=>{
		form.clear();
		setForm({...form});
		setEdit(-1);
	}

	const onSubmit = async ()=>{
		const resource = new Resource("users/self");
		let res = await resource.put(null, form.values());
		if(res.error) return broadcast.set("reset_password", {type:"error", message: res.error});
		return window.location.reload();
	}

	const onEdit = (value)=>{
		if(!value)
			form.setValues(user);
		setEdit(value);
	}

	return(
		<Card className="personal-summary" elevation={3}>
			<CardHeader
		        title="Personal Information"
		        titleTypographyProps={{variant: "h6"}}
		     />
		    <CardContent>
		    	<div>
		    		<div className="row-flex" style={{alignItems:"flex-start"}}>
					  	<div className="free-space" style={{marginTop: "15px"}}>
							{
								isEdit==2?(<div><UpdateEmail onCancel = {()=>setEdit(-1)} user={user}/></div>):(
								  <React.Fragment>
									  <div className="field-container row-flex">
									  	<div className="app-description field-label">Email</div>
									  	<strong>{user.email}</strong>
									  </div>
									  <div className="field-container row-flex">
									  	<div className="app-description field-label">Secondary Email</div>
									  	<strong>{user.secondaryEmail}</strong>
									  </div>	
								  </React.Fragment>		  
								)
							}
						</div>
						<React.Fragment>
							{
								isEdit==-1?(<IconButton aria-label="settings" onClick={()=>setEdit(2)}><Edit /></IconButton>):<React.Fragment/>
							}
						</React.Fragment>
					</div>
				  <Divider light />
				  
				  <div className="row-flex" style={{alignItems:"flex-start", padding: "10px 0"}}>
				  	<div className="free-space">
				  		{
				  			isEdit!=0?(
				  				<React.Fragment>
				  					<div className="field-container row-flex">
									  	<div className="app-description field-label">Name</div>
									  	<strong>{user.name}</strong>
									  </div>

									  <div className="field-container row-flex">
									  	<div className="app-description field-label">Phone Number</div>
									  	<strong>{user.phoneNumber}</strong>
									  </div>
				  				</React.Fragment>		
				  			):(
				  				<form  noValidate autoComplete="off">
				  					<div className="field-container field-container-form row-flex">
									  	<div className="app-description field-label">Name</div>
									  	<TextField 
									  		className="free-space" 
									  		required = {form.name.is_required}
							                error = {form.name.error?true:false}
							                id="name"
							                value={form.name.value || ""}
							                helperText={form.name.error||""}
							                variant="outlined"
							                onChange={(event)=>handleChange('name', event.target.value)}
									  		/>
									  </div>


									  <div className="field-container field-container-form row-flex">
									  	<div className="app-description field-label">Phone Number</div>
									  	 <div className={"MuiTextField-root free-space " + (form.phoneNumber.error?" error-MuiTextField-root":"")}>
							             <div className="personal-summary-input">
								             <PhoneInput
								              	  style={{width:'100%'}}
								                  country={'us'}
								                  inputProps={{
								                    id: 'phoneNumber',
								                    required: form.phoneNumber.is_required
								                  }}
								                  onChange={(val)=>handleChange('phoneNumber', val)}
								                  defaultErrorMessage= {form.phoneNumber.error||""}
								                  value={form.phoneNumber.value || form.phoneNumber.default||""}
								                   specialLabel={""}
								              />
							              <p className="phoneinput-error">{form.phoneNumber.error||""}</p>
							              </div>
							            </div>
									  </div>

									  <div style={{width:'100%', marginTop: '10px'}} className="row-flex center-flex">
									  	<React.Fragment>
						                  <Button  style={{width: "120px"}}  onClick={onCancel}>Cancel </Button> 
							                  <div style={{width: "20px"}}></div>
							                  <Button variant="contained" 
							                      disabled = {form.hasError()}
							                      style={{color:'white', minWidth: '120px', backgroundColor: form.hasError()?'#e5e5e5':'#629bf1'}}
							                      onClick={onSubmit}>
							                      Submit
							                  </Button>
							               </React.Fragment>
									  </div>
				  				</form>	
				  			)
				  		}
					</div>
					<React.Fragment>
						{
							isEdit==-1?(<IconButton aria-label="settings" onClick={()=>onEdit(0)}><Edit /></IconButton>):<React.Fragment/>
						}
			        </React.Fragment>
				  </div>
				  <React.Fragment>
					{user.loginType=="PasswordFlow"?(
						<React.Fragment>
							<Divider light />
							   <div className="field-container row-flex" style={{alignItems: "flex-start"}}>
							  	<div className="app-description field-label" style={{marginTop: "15px"}}>Password</div>
							  	<div className="free-space" style={{marginTop: "15px"}}>
						  			{
							  			isEdit==1?(<div className="personal-summary-input"><UpdatePassword onCancel = {()=>setEdit(-1)} /></div>):(<strong className="free-space">•••••••••••</strong>)
						  			}
							  	</div>
							  	<React.Fragment>
									{
										isEdit==-1?(<IconButton aria-label="settings" onClick={()=>setEdit(1)}><Edit /></IconButton>):<React.Fragment/>
									}
						        </React.Fragment>
							  </div>
						</React.Fragment>
					):(
						<React.Fragment/>
					)} 
				  </React.Fragment>
				</div>

		    </CardContent>
		</Card>
	)}

export default PersonalSummary;