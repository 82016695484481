import React from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import { Button } from '@material-ui/core';
import studentSessionForm from './../forms/studentSession';
import Information from "./../sdk/information/information";
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';

import PageAlert from './../sdk/pageAlert/pageAlert';
import Loading  from './../sdk/loading/loading';
import UserResource from './../resources/userResource';
import SessionResource from './../resources/sessionResource';
import { Broadcast } from './../sdk/broadcast';
import Select from '@material-ui/core/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import DateTimePicker from '@mui/lab/DateTimePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateAdapter from '@mui/lab/AdapterMoment';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Divider from '@mui/material/Divider';
import { data as configData } from './config';
import moment from 'moment';

const userResource = new UserResource();

const sessionResource = new SessionResource();

const sessionFeedback = configData.sessionFeedback;
function StudentSession({title, students, data, onAction, onCancel, loadingChannel, errorChannel, readOnly}) {
  if(!Array.isArray(students)) students = [students];
  const [_form, _setForm] = React.useState({...studentSessionForm});
  let [instructors, setInstructors] = React.useState([]);  
  const {broadcast} = React.useContext(Broadcast);
  const loading  = broadcast.get(loadingChannel);
  const [date, setDate] = React.useState(new Date());
  const loggedInUser = broadcast.get("loggedInUser");
  const handleChange = (id, value)=>{
    _form.onChange(id, value);
    _setForm({..._form});
  }
  
  const hasError = ()=>{
    return _form.hasError();
  }

  const onSubmit = ()=>{
    let item =_form.values();
    let d = moment(item.date).format('YYYY-MM-DD hh:mmA');
    if(d=='Invalid Date' || d=='Invalid date') d = moment(item.date, 'YYYY-MM-DD hh:mmA').format('YYYY-MM-DD hh:mmA');
    item = {
      instructorEmail: item.instructorEmail,
      course: item.course,
      sessionName: item.sessionName,
      date: d,
      emailNotification: item.emailNotification,
      sessionFeedback: {
          sessionOverallRating: item.sessionOverallRating || "",
          sessionNote: item.sessionNote || "",
          studentHomeworkStatus: item.studentHomeworkStatus || "",
          studentHomeworkFeedback: item.studentHomeworkFeedback || "",
          sessionMaterialFeedback: item.sessionMaterialFeedback  || "",
          sessionMaterialNote: item.sessionMaterialNote  || "",
          sessionDifficultyFeedback: item.sessionDifficultyFeedback  || "",
          sessionDifficultyNote: item.sessionDifficultyNote || "",
          sessionTimeCompletions: item.sessionTimeCompletions ||""
      },
      homeworkLink: item.homeworkLink||""
    }
    if(students && students.length && !data.id && !data._id) item.sessionName = `Session ${item.sessionName} Level  ${students[0].level}`;
    if(data.id || data._id) item.id = data.id || data._id;
    onAction({...item, students: students.map(val=>({id: val.id||val._id}))});
  }

   const getInstructors = async()=>{
    if(loggedInUser.userRole==1){
      instructors = [loggedInUser]
      setInstructors(instructors);
    }
    else{
      let res = await userResource.get(null, {"userRole": 1, "fields": "name, email"});
      if(res.error) return broadcast.set("student-error", {message: res.error, type:"error"});
      if(readOnly) res = res.filter(item=>item.email==data.instructorEmail);
      instructors = res;
      setInstructors(res);
    }
    // if(instructors.length) handleChange("instructorEmail", instructors[0].email);
  }

  const formatDate =(value)=>{
    if(!value) return new Date();
    if( value instanceof Date) return value;
    let t = new Date(value);
    if(t=='Invalid Date' || t=='Invalid date') t = moment(value, 'YYYY-MM-DD hh:mmA').toDate();
    if(t=='Invalid Date' || t=='Invalid date') t = moment(value, 'MM-DD-YYYY hh:mmA').toDate();
    return t;
  }

  const onDateChange = (id, value)=>{
    if(value.format('YYYY-MM-DD hh:mmA')=='Invalid date'){
      _form.date.error = "Invalid date! Please use date picker to fix it";
      return _setForm({..._form});
    }
    setDate(value);
    handleChange(id, value?value.format('YYYY-MM-DD hh:mmA'):'');
  }
  
  React.useEffect(()=>{
      _form.clear();
      if(!data.id && !data._id) data.course = (students[0]||{}).course ||"";
      data.date =  formatDate(data.date);
      if(data.id || data._id) data.emailNotification = false;
      setDate(data.date);
      _form.setValues(data);
      _setForm({..._form});
      getInstructors();
   }, []);



  return (
      <form noValidate autoComplete="off" className="row-flex center-flex student-session-form">
        <div className="row-flex center-flex form-title" data-aos="flip-down" data-aos-delay="100" data-aos-easing="ease-in-out"><Typography variant="h5">{title}</Typography ></div>
        <div style={{width:'100%'}} >
          <br/>

           <FormControl style={{width:'100%', height: '85px'}} variant="outlined" error = {_form.course.error?true:false} required = {_form.course.is_required}>
              <FormLabel component="legend" id="course">{_form.course.label + (_form.course.is_required?"*":"")}</FormLabel>
              <Select
                inputProps={{ readOnly: readOnly }}
                
                value={_form.course.value || _form.course.default||"Scratch"}
                onChange={(event)=>{handleChange('course', event.target.value)}}

              >
                {sessionFeedback.course.map((item, index)=><MenuItem key={index} value={item}>{item}</MenuItem>)}
              </Select>
              <FormHelperText>{_form.course.error||""}</FormHelperText>
          </FormControl>

           <React.Fragment>
            {
              !loggedInUser.userRole?<React.Fragment/>:
              instructors.length?(
               <FormControl style={{width:'100%', height: '85px'}} variant="outlined" error = {_form.instructorEmail.error?true:false} required = {_form.instructorEmail.is_required}>
                <FormLabel component="legend" id="instructorEmail">{_form.instructorEmail.label + (_form.instructorEmail.is_required?"*":"")}</FormLabel>
                  <Select
                    inputProps={{ readOnly: readOnly }}
                    value={_form.instructorEmail.value || _form.instructorEmail.default||""}
                      onChange={(event)=>{handleChange('instructorEmail', event.target.value)}}
                  > 
                    
                      {instructors.map((item, index)=>(
                      <MenuItem value={item.email} key={index}>{item.name}</MenuItem>
                      ))}
                  </Select>
                  <FormHelperText>{_form.instructorEmail.error||""}</FormHelperText>
              </FormControl>
              ):(
                <FormControl style={{width:'100%'}} variant="outlined" error = {_form.instructorEmail.error?true:false} required = {_form.instructorEmail.is_required}>
                  <FormLabel component="legend" id="instructorEmail">{_form.instructorEmail.label + (_form.instructorEmail.is_required?"*":"")}</FormLabel>
                  <OutlinedInput  
                    inputProps={{ readOnly: readOnly }}
                    aria-describedby="instructionEmail" 
                    value={_form.instructorEmail.value || _form.instructorEmail.default||""}
                    onChange={(event)=>handleChange('instructorEmail', event.target.value)}
                  />
                  <FormHelperText>{_form.instructorEmail.error||""}</FormHelperText>
                </FormControl>
                
              )
            }
           
          </React.Fragment>


          <FormControl style={{width:'100%', height: '85px'}} variant="outlined" error = {_form.sessionName.error?true:false} required = {_form.sessionName.is_required}>
            {(!data.id && !data._id)?
              (<React.Fragment>
                    <FormLabel component="legend" id="sessionName">{_form.sessionName.label + (_form.sessionName.is_required?"*":"")}</FormLabel>
                      <Select
                        value={_form.sessionName.value || _form.sessionName.default||0}
                          onChange={(event)=>{handleChange('sessionName', event.target.value)}}
                      >
                        {[...Array(8).keys()].map((item, index)=>(
                            <MenuItem value={(index + 1)} key={index}>{"Session " + (index + 1)}</MenuItem>
                        ))}

                      </Select>
                      <FormHelperText>{_form.sessionName.error||""}</FormHelperText>
              </React.Fragment>):
              (<React.Fragment>
                <FormLabel component="legend" id="sessionName">{_form.sessionName.label + (_form.sessionName.is_required?"*":"")}</FormLabel>
                  <OutlinedInput  
                    inputProps={{ readOnly: readOnly }}
                    aria-describedby="sessionName" 
                    value={_form.sessionName.value || _form.sessionName.default||""}
                    onChange={(event)=>handleChange('sessionName', event.target.value)}

                  />
                  <FormHelperText>{_form.sessionName.error||""}</FormHelperText>
              </React.Fragment>)
            }
          </FormControl>

         
          <FormControl style={{width:'100%'}}>
             <LocalizationProvider dateAdapter={DateAdapter}>
              <FormLabel component="legend" id="date">{_form.date.label + (_form.date.is_required?"*":"")}</FormLabel>
              <DateTimePicker
                readOnly = {readOnly}
                style={{width:'100%'}}
                helperText={_form.date.error||""}
                value={date}
                onChange={(event)=>onDateChange('date', event)} required = {_form.date.is_required}
                renderInput={(params) => <TextField {...params} variant="outlined"  style={{width:'100%'}} error = {_form.date.error?true:false} helperText={_form.date.error||""}
                id="date"
                />}
              />
              </LocalizationProvider>
          </FormControl>


          
          <FormControl style={{width:'100%'}} variant="outlined" error = {_form.sessionOverallRating.error?true:false} required = {_form.sessionOverallRating.is_required}>
            <FormLabel component="legend" id="sessionOverallRating">{_form.sessionOverallRating.label + (_form.sessionOverallRating.is_required?"*":"")}</FormLabel>
              <RadioGroup
                  aria-label="sessionOverallRating"
                  name="sessionOverallRating"
                  value={_form.sessionOverallRating.value || _form.sessionOverallRating.default||""}
                  onChange={(event)=>handleChange('sessionOverallRating', event.target.value)}
              >
                  {sessionFeedback.sessionOverallRating.map((item, index)=><FormControlLabel value={item} control={<Radio disabled={readOnly && _form.sessionOverallRating.value!=item} />} label={item} key={index} />)}
                                  
              </RadioGroup>    
              <FormHelperText>{_form.sessionOverallRating.error||""}</FormHelperText>
          </FormControl>

          <FormControl style={{width:'100%'}} variant="outlined" error = {_form.sessionNote.error?true:false} required = {_form.sessionNote.is_required}>
                  <FormLabel component="legend" id="sessionNote">{_form.sessionNote.label  + (_form.sessionNote.is_required?"*":"")}</FormLabel>
                  <OutlinedInput  
                    inputProps={{ readOnly: readOnly }}
                    aria-describedby="sessionNote" 
                    value={_form.sessionNote.value || _form.sessionNote.default||""}
                    onChange={(event)=>handleChange('sessionNote', event.target.value)}
                    multiline
                    rows={5}
                  />
                  <FormHelperText>{_form.sessionNote.error||""}</FormHelperText>
          </FormControl>
          

          <FormControl style={{width:'100%'}} variant="outlined" error = {_form.studentHomeworkStatus.error?true:false} required = {_form.studentHomeworkStatus.is_required}>
            <FormLabel component="legend" id="studentHomeworkStatus">{_form.studentHomeworkStatus.label + (_form.studentHomeworkStatus.is_required?"*":"")}</FormLabel>
              <RadioGroup
                  aria-label="studentHomeworkStatus"
                  name="studentHomeworkStatus"
                  value={_form.studentHomeworkStatus.value || _form.studentHomeworkStatus.default||""}
                  onChange={(event)=>handleChange('studentHomeworkStatus', event.target.value)}
              >
                {sessionFeedback.studentHomeworkStatus.map((item, index)=><FormControlLabel value={item} control={<Radio disabled={readOnly && _form.studentHomeworkStatus.value!=item} />} label={item} key={index} />)}    
               </RadioGroup>    
              <FormHelperText>{_form.studentHomeworkStatus.error||""}</FormHelperText>
          </FormControl>


          <React.Fragment>
            {!loggedInUser.userRole?<React.Fragment/>: 
             (<React.Fragment>
               <FormControl style={{width:'100%'}} variant="outlined" error = {_form.studentHomeworkFeedback.error?true:false} required = {_form.studentHomeworkFeedback.is_required}>
                <FormLabel component="legend" id="studentHomeworkFeedback">{_form.studentHomeworkFeedback.label + (_form.studentHomeworkFeedback.is_required?"*":"")}</FormLabel>
                  <RadioGroup
                      aria-label="studentHomeworkFeedback"
                      name="studentHomeworkFeedback"
                      value={_form.studentHomeworkFeedback.value || _form.studentHomeworkFeedback.default||""}
                      onChange={(event)=>handleChange('studentHomeworkFeedback', event.target.value)}
                  >
                    {sessionFeedback.studentHomeworkFeedback.map((item, index)=><FormControlLabel value={item} control={<Radio disabled={readOnly && _form.studentHomeworkFeedback.value!=item} />} label={item} key={index} />)}    
                  </RadioGroup>    
                  <FormHelperText>{_form.studentHomeworkFeedback.error||""}</FormHelperText>
              </FormControl>

              <FormControl style={{width:'100%'}} variant="outlined" error = {_form.sessionMaterialFeedback.error?true:false} required = {_form.sessionMaterialFeedback.is_required}>
                <FormLabel component="legend" id="sessionMaterialFeedback">{_form.sessionMaterialFeedback.label + (_form.sessionMaterialFeedback.is_required?"*":"")}</FormLabel>
                  <RadioGroup
                      aria-label="sessionMaterialFeedback"
                      name="sessionMaterialFeedback"
                      value={_form.sessionMaterialFeedback.value || _form.sessionMaterialFeedback.default||""}
                      onChange={(event)=>handleChange('sessionMaterialFeedback', event.target.value)}
                  >
                     {sessionFeedback.sessionMaterialFeedback.map((item, index)=><FormControlLabel value={item} control={<Radio disabled={readOnly && _form.sessionMaterialFeedback.value!=item} />} label={item} key={index} />)}    

                  </RadioGroup>    
                  <FormHelperText>{_form.sessionMaterialFeedback.error||""}</FormHelperText>
              </FormControl>

              <React.Fragment>
                {
                  (_form.sessionMaterialFeedback.value==sessionFeedback.sessionMaterialFeedback[1] || _form.sessionMaterialFeedback.value==sessionFeedback.sessionMaterialFeedback[2])?
                  <FormControl style={{width:'100%'}} variant="outlined" error = {_form.sessionMaterialNote.error?true:false} required = {_form.sessionMaterialNote.is_required}>
                    <FormLabel component="legend" id="sessionMaterialNote">{_form.sessionMaterialNote.label + (_form.sessionMaterialNote.is_required?"*":"")}</FormLabel>
                    <OutlinedInput  
                        inputProps={{ readOnly: readOnly }}
                        aria-describedby="sessionMaterialNote" 
                        value={_form.sessionMaterialNote.value || _form.sessionMaterialNote.default||""}
                        onChange={(event)=>handleChange('sessionMaterialNote', event.target.value)}
                        multiline
                        rows={5}
                      />
                    <FormHelperText>{_form.sessionMaterialNote.error||""}</FormHelperText>
                  </FormControl>:<React.Fragment/>
                }
              </React.Fragment>
              
              <FormControl style={{width:'100%'}} variant="outlined" error = {_form.sessionTimeCompletions.error?true:false} required = {_form.sessionTimeCompletions.is_required}>
                <FormLabel component="legend" id="sessionTimeCompletions">{_form.sessionTimeCompletions.label + (_form.sessionTimeCompletions.is_required?"*":"")}</FormLabel>
                  <RadioGroup
                      aria-label="sessionTimeCompletions"
                      name="sessionTimeCompletions"
                      value={_form.sessionTimeCompletions.value || _form.sessionTimeCompletions.default||""}
                      onChange={(event)=>handleChange('sessionTimeCompletions', event.target.value)}
                  >
                    {sessionFeedback.sessionTimeCompletions.map((item, index)=><FormControlLabel value={item} control={<Radio disabled={readOnly && _form.sessionTimeCompletions.value!=item} />} label={item} key={index} />)}    
                   </RadioGroup>    
                  <FormHelperText>{_form.sessionTimeCompletions.error||""}</FormHelperText>
              </FormControl>

              <FormControl style={{width:'100%'}} variant="outlined" error = {_form.sessionDifficultyFeedback.error?true:false} required = {_form.sessionDifficultyFeedback.is_required}>
                <FormLabel component="legend" id="sessionDifficultyFeedback">{_form.sessionDifficultyFeedback.label + (_form.sessionDifficultyFeedback.is_required?"*":"")}</FormLabel>
                  <RadioGroup
                      aria-label="sessionDifficultyFeedback"
                      name="sessionDifficultyFeedback"
                      value={_form.sessionDifficultyFeedback.value || _form.sessionDifficultyFeedback.default||""}
                      onChange={(event)=>handleChange('sessionDifficultyFeedback', event.target.value)}
                  >
                    {sessionFeedback.sessionDifficultyFeedback.map((item, index)=><FormControlLabel value={item} control={<Radio disabled={readOnly && _form.sessionDifficultyFeedback.value!=item} />} label={item} key={index} />)}    
                  </RadioGroup>    
                  <FormHelperText>{_form.sessionDifficultyFeedback.error||""}</FormHelperText>
              </FormControl>

               <React.Fragment>
                {
                  (_form.sessionDifficultyFeedback.value==sessionFeedback.sessionDifficultyFeedback[sessionFeedback.sessionDifficultyFeedback.length-1])?
                  <FormControl style={{width:'100%'}} variant="outlined" error = {_form.sessionDifficultyNote.error?true:false} required = {_form.sessionDifficultyNote.is_required}>
                    <FormLabel component="legend" id="sessionDifficultyNote">{_form.sessionDifficultyNote.label + (_form.sessionDifficultyNote.is_required?"*":"")}</FormLabel>
                    <OutlinedInput  
                        inputProps={{ readOnly: readOnly }}
                        aria-describedby="sessionDifficultyNote"  
                        value={_form.sessionDifficultyNote.value || _form.sessionDifficultyNote.default||""}
                        onChange={(event)=>handleChange('sessionDifficultyNote', event.target.value)}
                        multiline
                        rows={5}
                      />
                    <FormHelperText>{_form.sessionDifficultyNote.error||""}</FormHelperText>
                  </FormControl>:<React.Fragment/>
                }
              </React.Fragment>
            </React.Fragment>)
          } 

          <br />
          <br />

          <Divider light />
          <br />
          <br />

          <FormControl style={{width:'100%'}} variant="outlined" error = {_form.homeworkLink.error?true:false} required = {_form.homeworkLink.is_required}>
                  <FormLabel component="legend" id="homeworkLink">{_form.homeworkLink.label + (_form.homeworkLink.is_required?"*":"")}</FormLabel>
                  <OutlinedInput  
                    inputProps={{ readOnly: readOnly }}
                    aria-describedby="homeworkLink" 
                    value={_form.homeworkLink.value || _form.homeworkLink.default||""}
                    onChange={(event)=>handleChange('homeworkLink', event.target.value)}
                  />
                  <FormHelperText>{_form.homeworkLink.error||""}</FormHelperText>
          </FormControl>

          <React.Fragment>
            {
              readOnly?<React.Fragment/>:
              (<FormControl>
                <FormControlLabel
                      control={
                        <Checkbox
                          id="emailNotification"
                          checked={_form.emailNotification.value?true:false}
                          onChange={(event)=>handleChange('emailNotification', event.target.checked)}
                          name="emailNotification"
                          color="primary"
                        />
                      }
                      label={_form.emailNotification.label}
                    />
              </FormControl>)
            }
          </React.Fragment>

        </React.Fragment>

          <div style={{width:'100%', marginTop: '10px'}} className="row-flex center-flex">
            {
                loading?<React.Fragment/>:
                (
                  readOnly?<Button  style={{width: "120px"}} onClick={()=>onCancel()}>Close</Button>:
                    (<React.Fragment>
                      <Button  style={{width: "120px"}} onClick={()=>onCancel()}>Cancel </Button> 
                      <div style={{width: "20px"}}></div>
                      <Button variant="contained" 
                          disabled = {hasError()}
                          style={{color:'white', minWidth: '120px', backgroundColor: hasError()?'#e5e5e5':'#629bf1'}}
                          onClick={()=>onSubmit(data)}>
                          Submit
                      </Button>
                    </React.Fragment>)
                  
                )
              }
          </div>
        
          <div className="row-flex center-flex" style={{width: "100%", minHeight:"50px", marginTop:"50px"}}><PageAlert id={errorChannel} className={errorChannel} /></div>
          <div className="row-flex center-flex" style={{width: "100%", minHeight:"50px", marginTop:"50px"}}><Loading id={loadingChannel} className={loadingChannel} loadingType="circular"/></div>
        </div>
      </form>
  );
}

export default StudentSession;
