import React, {useEffect} from 'react';
import Dialog from "@material-ui/core/Dialog";
import Drawer from '@material-ui/core/Drawer';

import CloseIcon from "@material-ui/icons/Close";

import { Slide, Button,IconButton, Icon } from '@material-ui/core';

import WindowResize from './../sdk/windowResize';

import SessionResource from './../resources/sessionResource';
import Tooltip from '@material-ui/core/Tooltip';

import { Broadcast } from "./../sdk/broadcast";
import Confirmation from './../sdk/confirmation/confirmation';

import StudentSession from './../students/studentSession';

import SDKTable from './../sdk/sdkTable/sdkTable';

import SessionRow from './sessionRow';
import { data } from './config';

import './sessions.css';

const sessionResource = new SessionResource();

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

let tableHeaders = data.headers;


const Sessions = ()=> { 
  const size = WindowResize();
  const {broadcast} = React.useContext(Broadcast);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [formContent, setFormContent] = React.useState(<React.Fragment/>);
  let [search, setSearch] = React.useState(null);

  const loggedInUser = broadcast.get("loggedInUser");

  const onTableAction = (actionName, data)=>{
      if(tableAction[actionName])
      tableAction[actionName](data);
  }

  const tableAction = {
    viewSessionDetail: (data)=>{
        let tmp = {...data};
        Object.keys(data.sessionFeedback).forEach(item=>tmp[item]=data.sessionFeedback[item]);
        delete tmp.sessionFeedback;
        broadcast.delete("student-session-error");    
        setFormContent(
          <StudentSession 
            title="Student Session" 
            onAction={()=>{}} 
            onCancel={onDialogClose} 
            students={[]}
            data = {tmp}
            loadingChannel="student-session-loading"
            errorChannel="student-session-error"
            readOnly={true}
          >
          </StudentSession>
       );
       setOpenDialog(true);
    },
    editSessionDetail: (data)=>{
        if(!loggedInUser || loggedInUser.userRole!==2) return;
        let tmp = {...data};
        Object.keys(data.sessionFeedback).forEach(item=>tmp[item]=data.sessionFeedback[item]);
        delete tmp.sessionFeedback;
        broadcast.delete("student-session-error");    
        setFormContent(
          <StudentSession 
            title="Student Session" 
            onAction={tableAction._updateSession} 
            onCancel={onDialogClose} 
            students={[]}
            data = {tmp}
            loadingChannel="student-session-loading"
            errorChannel="student-session-error"
          >
          </StudentSession>
       );
       setOpenDialog(true);
    },
    _updateSession: async(data)=>{
      if(!loggedInUser || loggedInUser.userRole!==2) return;
      const _data = {...data};
      const id = _data.id || _data._id;
      delete _data.id;
      delete _data._id;
      delete _data.students;
      broadcast.delete("student-session-loading");
      broadcast.delete("student-session-error");    
      broadcast.set("student-session-loading", true);
      let res = await sessionResource.put(null, _data, id);
      broadcast.set("student-session-loading", false);
      if(res.error)
        return broadcast.set("student-session-error", {message: res.error, type:"error"});
      setConfirmationContent("", "Student session has been updated", null, onDialogClose);

      return broadcast.set("refreshSessionTable", Date.now());
    }
  }
    
  const onDialogClose = ()=>{
      setFormContent(<React.Fragment/>)
      console.log("onDialogClose");
      setOpenDialog(false);
   }

    const setConfirmationContent = (title, children, onSubmit, onCancel, data=[], loadingChannel="", errorChannel="")=>{
      setFormContent(
      <Confirmation 
        title={title}
        onSubmit={onSubmit} 
        onCancel={onCancel} 
        data={data}
        loadingChannel={loadingChannel}
        errorChannel={errorChannel}
      >
        <div>{children}</div>
      </Confirmation>
    );
   }

   React.useEffect(()=>{
        if(loggedInUser.userRole==0) return window.location.href= "/";
      }, []);

  return (
    <div className="page-padding">
      <React.Fragment>
        {(size.width<=850)?(
          <Dialog
            disableEnforceFocus
            disableEscapeKeyDown
            disableBackdropClick 
            className="form-dialog"
              disableEscapeKeyDown={true}
              open={openDialog}
              onClose={evt=>onDialogClose()}
              TransitionComponent={Transition}
            > 
              <div className="form-container row-flex center-flex" style={{position: "relative", height: "100%", width:"100%"}}>
                <div className="close-form">
                  <IconButton aria-label="expand row" size="small" onClick={()=>onDialogClose()}>
                      <Icon>clear</Icon>
                  </IconButton>
                </div>
                <div style={{width:  size.width>=850?'70%':'90%'}}>{formContent}</div>
              </div>
          </Dialog>):(
          <Drawer 
            disableEnforceFocus
            disableEscapeKeyDown
            disableBackdropClick 
            anchor='right' 
            open={openDialog} 
            onClose={evt=>onDialogClose()}
            >
              <div className="form-container row-flex center-flex" style={{height: "100%", width: "650px", position: "relative",}} >
                <div className="close-form">
                  <IconButton aria-label="expand row" size="small" onClick={()=>onDialogClose()}>
                      <Icon>clear</Icon>
                  </IconButton>
                </div>
                <div style={{width:  size.width>=850?'70%':'90%'}}>{formContent}</div>
              </div>
            </Drawer>)
        }
      </React.Fragment>

      <SDKTable 
        rowId={"_id"}
        tableHeaders={tableHeaders} 
        refreshChannel={"refreshSessionTable"}
        defaultOrder={{name:'date', value:'desc'}}
        resource={sessionResource}
        hasSearch={1}
        inlineAction={1}
        onRowAction={onTableAction}
      >
        <SessionRow/>
      </SDKTable>
    </div>
    
  );
}

export default Sessions;
