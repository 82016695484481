import React, {useContext} from 'react';
import { Broadcast } from './../sdk/broadcast';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import { Button } from '@material-ui/core';
import { data, style } from './config';
import './resetPassword.css';

function ResetPassword(props) {
  const _form = props.form;
  return (
      <React.Fragment>
  
        <div style={{width:'100%'}} className="account column-flex form-container">
            <div>
              <TextField
                style={{width:'100%'}}
                required = {_form.password.is_required}
                error = {_form.password.error?true:false}
                id="password"
                value={_form.password.value || _form.password.default||""}
                label={_form.password.label}
                helperText={_form.password.error||""}
                variant="outlined"
                onChange={(event)=>props.handleChange('password', event.target.value)}
                type="password"
              />
            </div> 

            <div>
              <TextField
                style={{width:'100%'}}
                required = {_form.retypePassword.is_required}
                error = {_form.retypePassword.error?true:false}
                id="retypePassword"
                value={_form.retypePassword.value || _form.retypePassword.default||""}
                label={_form.retypePassword.label}
                helperText={_form.retypePassword.error||""}
                variant="outlined"
                onChange={(event)=>props.handleChange('retypePassword', event.target.value)}
                type="password"
              />
            </div> 
        </div>
      </React.Fragment>
  );
}

export default ResetPassword;
