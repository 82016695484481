import SDKForm from './../sdk/sdkForm';
const studentForm = {
	...SDKForm,
	'studentName': {label: "Student Name", is_required: true, icon: 'face', description: 'This helps us in pairing your kid with similar age kids for awesome learning'},
	'studentAge': {
		label: "Student Age", 
		is_required: true,
		validation: function(val){
			this.error = '';
			if(parseInt(val)!=val) return this.error ='Invalid Number';		
			if(val<7 || val>16) return this.error = 'The class sessions are only available for students from 7 to 16 year olds';
		}
	},
	'studentEmail': { 
		label: "Student Email", 
		error: '', 
		validation: function(val){
			this.error = '';
				if(!(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test((val||"").toLowerCase()))
			this.error = 'Invalid Email';
		}
	},
	'level': {label: "Level", is_required: true, value: 1, default: 1},
	'course': {label: "Course", is_required: true, value: "Scratch", default: "Scratch"},
	'sessionDateTime': {label: "Session Times", is_required: true},
	'sessionType': {label: "Summer Session", is_required: true, value:'regular', default: 'regular'},
	'instructorEmail': {
		label: "Instructor Email", 
		is_required: true, 
		validation: function(val){
			this.error = '';
				if(!(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test((val||"").toLowerCase()))
			this.error = 'Invalid Email';
		}
	},
	'instructorName': {label: "Instructor Name", is_required: true},
	'isActive': {label: "Student Status", value: true},
	'emailNotification': {label: "Send a email to notify user", value: false},

}

export default studentForm;