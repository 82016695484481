import React from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import { Button } from '@material-ui/core';
import { data, style } from './config';

function PasswordFlow(props) {
  const [_form, _setForm] = React.useState({...data.form});
  const handleChange = (id, value)=>{
    _form.onChange(id, value);
    _setForm({..._form});
  }
  
  const hasError = ()=>{
    return _form.hasError(['email', 'password']);
  }

  return (
      <form noValidate autoComplete="off" className="row-flex center-flex">
  
        <div style={{width:'100%'}} className="account column-flex form-container">
	          <div>
	            <TextField
	              style={{width:'100%'}}
	              required = {_form.email.is_required}
	              error = {_form.email.error?true:false}
	              id="email"
	              value={_form.email.value || _form.email.default||""}
	              label={_form.email.label}
	              helperText={_form.email.error||""}
	              variant="outlined"
	              onChange={(event)=>handleChange('email', event.target.value)}
	            />
	          </div>           
	          <div>
	            <TextField
	              style={{width:'100%'}}
	              required = {_form.password.is_required}
	              error = {_form.password.error?true:false}
	              id="password"
	              value={_form.password.value || _form.password.default||""}
	              label={_form.password.label}
	              helperText={_form.password.error||""}
	              variant="outlined"
	              onChange={(event)=>handleChange('password', event.target.value)}
	              type="password"
	            />
	          </div> 


	        <div style={{width:'100%', marginTop: '10px'}} className="row-flex center-flex">
	            <Button variant="contained" 
	                disabled = {hasError()}
	                 style={{color:'white', width: '100%', backgroundColor: hasError()?'#e5e5e5':'#629bf1'}}
	                     onClick={()=>props.submit('token', 'PasswordFlow', _form.values())}>Login</Button>
	        </div>
        </div>
      </form>
  );
}

export default PasswordFlow;