import {createContext} from 'react';
import React, {useState, useContext, useEffect, useMemo} from 'react';

// const Broadcast = createContext({});

// const BroadcastProvider = ({children})=>{
// 	const [channels, setChannels] = useState({});
// 	const memo  = useMemo(()=>({channels, setChannels}), [channels, setChannels]);
// 	let broadcast = {
// 	    set:(key, val)=>{
// 	     console.log("set with key: " + key);
// 	      memo.channels[key] = val;
// 	      memo.setChannels({...memo.channels});
// 	    },
// 	    get:(key)=>{
// 	      return memo.channels[key];
// 	    },
// 	    delete:(key)=>{
// 	      delete memo.channels[key];
// 	      memo.setChannels({...memo.channels});
// 	    }
// 	}

// 	return (
// 		<Broadcast.Provider value={{broadcast}}>
// 			{children}
// 		</Broadcast.Provider>
// 	);
// }



const reducer = (state, action)=>{
	if(action.method=='DELETE') delete state[action.key];
	else state[action.key] = action.value;
	return {...state};
} 

const Broadcast = createContext({});

const BroadcastProvider = ({children})=>{
	const [state, dispatch] = React.useReducer(reducer, {});
	let broadcast = {
	    set:(key, value)=>{
	      dispatch({key,value});
	    },
	    get:(key)=>{
	      return state[key];
	    },
	    delete:(key)=>{
	      dispatch({key, method:'DELETE'});
	    },
	    notify:(key)=>{
	    	dispatch({key, value: Date.now()})
	    }
	}
	return (
		<Broadcast.Provider value={{broadcast}}>
			{children}
		</Broadcast.Provider>
	);
}


export  {Broadcast, BroadcastProvider};