import React from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import { Button } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import PhoneInput from 'react-phone-input-2';
import studentForm from './../forms/student';
import basicStudentForm from './../forms/basicStudent';

import Information from "./../sdk/information/information";
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';

import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import PageAlert from './../sdk/pageAlert/pageAlert';
import UserResource from './../resources/userResource';
import { Broadcast } from "./../sdk/broadcast";
import CircularProgress from '@material-ui/core/CircularProgress';

import 'react-phone-input-2/lib/material.css';

const userResource = new UserResource();


function StudentDetail({data, user, onAction, onCancel}) {
  const [instructors, setInstructors] = React.useState([]);
  const {broadcast} = React.useContext(Broadcast);
  const loggedInUser = broadcast.get("loggedInUser");
  const [loading, setLoading] = React.useState(false);

  let sForm = loggedInUser.userRole===0?basicStudentForm:studentForm;
  const [_form, _setForm] = React.useState({...sForm});

  const handleChange = (id, value)=>{
    if(instructors.length && id=="instructorName"){
      let email = "";
      for(let i =0;i< instructors.length; i++){
        if(instructors[i].name === value){
          email = instructors[i].email;
          break;
        }
      }
      _form.onChange("instructorEmail", email);
    }
    _form.onChange(id, value);
    _setForm({..._form});
  }
  
  const hasError = ()=>{
    return _form.hasError();
  }

  const onSubmit = ()=>{
    let item =_form.values();
    item.email = user.email;
    if(data) item._id = data._id;
    setLoading(true);
    onAction(item, ()=>{
      setLoading(false);
    });
  }

  const getInstructors = async()=>{
    const res = await userResource.get(null, {"userRole": 1, "fields": "name, email"});
    if(res.error) return broadcast.set("student-error", {message: res.error, type:"error"});
    setInstructors(res);
  }

  React.useEffect(()=>{
      _form.clear();
      _form.setValues(data);
      _setForm({..._form});
      if(loggedInUser.userRole!=0) getInstructors();
   }, []);


  return (
      <form noValidate autoComplete="off" className="row-flex center-flex">
        <div className="row-flex center-flex" data-aos="flip-down" data-aos-delay="100" data-aos-easing="ease-in-out"><Typography variant="h5">{data?"Edit Student":"New Student"}</Typography ></div>
        <div style={{width:'100%'}} className="account column-flex form-container">
          <br/>
          <div>
            <TextField
              style={{width:'100%'}}
              required = {_form.studentName.is_required}
              error = {_form.studentName.error?true:false}
              id="studentName"
              value={_form.studentName.value || _form.studentName.default||""}
              label={_form.studentName.label}
              helperText={_form.studentName.error||""}
              variant="outlined"
              onChange={(event)=>handleChange('studentName', event.target.value)}
            />
          </div>

           <div>
            <TextField
              style={{width:'100%'}}
              required = {_form.studentEmail.is_required}
              error = {_form.studentEmail.error?true:false}
              id="studentEmail"
              value={_form.studentEmail.value || _form.studentEmail.default||""}
              label={_form.studentEmail.label}
              helperText={_form.studentEmail.error||""}
              variant="outlined"
              onChange={(event)=>handleChange('studentEmail', event.target.value)}
            />
          </div>

          <React.Fragment>
            {
              loggedInUser.userRole===0?<React.Fragment/>:
              (
                <React.Fragment>
                  <div>
                    <TextField
                      style={{width:'100%'}}
                      required = {_form.studentAge.is_required}
                      error = {_form.studentAge.error?true:false}
                      id="studentAge"
                      label={_form.studentAge.label}
                      value={_form.studentAge.value || _form.studentAge.default||""}
                      helperText={_form.studentAge.error||""}
                      variant="outlined"
                      onChange={(event)=>handleChange('studentAge', event.target.value)}
                      type="number"
                      inputProps={{min:_form.studentAge.min, max: _form.studentAge.max }}
                    />
                  </div> 
                  
                  <div style={{display:instructors.length?'none':'initial'}}>
                    <TextField
                      style={{width:'100%'}}
                      required = {_form.instructorEmail.is_required}
                      error = {_form.instructorEmail.error?true:false}
                      id="instructorName"
                      label={_form.instructorEmail.label}
                      value={_form.instructorEmail.value || _form.instructorEmail.default||""}
                      helperText={_form.instructorEmail.error||""}
                      variant="outlined"
                      onChange={(event)=>handleChange('instructorEmail', event.target.value)}
                    />
                  </div> 

                  <React.Fragment>
                    {
                      instructors.length?(
                         <FormControl style={{width:'100%', height: '85px'}} variant="outlined" error = {_form.instructorName.error?true:false} required = {_form.instructorName.is_required}>
                            <InputLabel id="instructorName">{_form.instructorName.label}</InputLabel>
                            <Select
                              native
                              value={_form.instructorName.value || _form.instructorName.default||0}
                              label={_form.instructorName.label}
                                onChange={(event)=>{handleChange('instructorName', event.target.value)}}
                            > 
                              <option aria-label="None" value="" />
                              <React.Fragment>
                                {instructors.map((item, index)=>(
                                  <option value={item.name} key={index}>{item.name}</option>
                                ))}
                              </React.Fragment>
                              </Select>
                              <FormHelperText>{_form.instructorName.error||""}</FormHelperText>
                          </FormControl>
                      ):(
                        <div>
                          <TextField
                            style={{width:'100%'}}
                            required = {_form.instructorName.is_required}
                            error = {_form.instructorName.error?true:false}
                            id="instructorName"
                            label={_form.instructorName.label}
                            value={_form.instructorName.value || _form.instructorName.default||""}
                            helperText={_form.instructorName.error||""}
                            variant="outlined"
                            onChange={(event)=>handleChange('instructorName', event.target.value)}
                          />
                        </div>
                      )
                    }
                   
                  </React.Fragment>

                  <FormControl style={{width:'100%', height: '85px'}} variant="outlined" error = {_form.course.error?true:false} required = {_form.course.is_required}>
                      <InputLabel id="course">{_form.course.label}</InputLabel>
                      <Select
                        native
                        value={_form.course.value || _form.course.default||0}
                        label={_form.course.label}
                          onChange={(event)=>{handleChange('course', event.target.value)}}
                      >
                        <option value={"Scratch"}>Scratch</option>
                        <option value={"App Lab"}>App Lab</option>
                        <option value={"Python"}>Python</option>
                        <option value={"Java"}>Java</option>

                      </Select>
                      <FormHelperText>{_form.course.error||""}</FormHelperText>
                  </FormControl>

                  <FormControl style={{width:'100%', height: '85px'}} variant="outlined" error = {_form.level.error?true:false} required = {_form.level.is_required}>
                      <InputLabel id="level">{_form.level.label}</InputLabel>
                      <Select
                        native
                        value={_form.level.value || _form.level.default||0}
                        label={_form.level.label}
                          onChange={(event)=>{handleChange('level', event.target.value)}}
                      >
                        <React.Fragment>
                        {[...Array(8).keys()].map((item, index)=>(
                            <option value={(index + 1)} key={index}>{"Level " + (index + 1)}</option>
                        ))}
                        </React.Fragment>
                        <React.Fragment>
                          {_form.course.value=='Python'? <option value={9} key={8}>{"Level 9"}</option>:<React.Fragment />}
                        </React.Fragment>
                      </Select>
                      <FormHelperText>{_form.level.error||""}</FormHelperText>
                  </FormControl>

                  <div>
                    <TextField
                      style={{width:'100%'}}
                      required = {_form.sessionDateTime.is_required}
                      error = {_form.sessionDateTime.error?true:false}
                      id="sessionDateTime"
                      value={_form.sessionDateTime.value || _form.sessionDateTime.default||""}
                      label={_form.sessionDateTime.label}
                      helperText={_form.sessionDateTime.error||""}
                      variant="outlined"
                      onChange={(event)=>handleChange('sessionDateTime', event.target.value)}
                    />
                  </div>

                   <React.Fragment>
                    {
                      (!data)?(<React.Fragment/>):
                        (<div className="row-flex" style={{alignItems: 'center'}}>
                          <FormLabel component="legend" style={{marginRight: '10px'}}>{_form.isActive.label}</FormLabel>
                          <FormControlLabel
                            control={
                              <Checkbox
                                id="isActive"
                                checked={_form.isActive.value?true:false}
                                onChange={(event)=>handleChange('isActive', event.target.checked)}
                                name="isActive"
                                color="primary"
                              />
                            }
                            label={_form.isActive.value?"Active":"Inactive"}
                          />
                        </div>)
                    }
                    
                  </React.Fragment>

                  <React.Fragment>
                    {
                      data?(<React.Fragment/>):
                        (<FormControlLabel
                          control={
                            <Checkbox
                              id="emailNotification"
                              checked={_form.emailNotification.value?true:false}
                              onChange={(event)=>handleChange('emailNotification', event.target.checked)}
                              name="emailNotification"
                              color="primary"
                            />
                          }
                          label={_form.emailNotification.label}
                        />)
                    }
                    
                  </React.Fragment>
                </React.Fragment>
              )
            }
          </React.Fragment>
          
          <React.Fragment>{
             loading?(<div style={{width:"100%"}} className="row-flex center-flex"><CircularProgress className="app-loading" /></div>):
            (<div style={{width:'100%', marginTop: '10px'}} className="row-flex center-flex">
                <Button  style={{width: "120px"}} onClick={()=>{_form.clear(); onCancel();}}>Cancel </Button> 
                 <div style={{width: "20px"}}></div>
                <Button variant="contained" 
                    disabled = {hasError()}
                     style={{color:'white', minWidth: '120px', backgroundColor: hasError()?'#e5e5e5':'#629bf1'}}
                         onClick={()=>onSubmit()}>Submit</Button>
            </div>)          
          }</React.Fragment>
        
          <div className="row-flex center-flex" style={{width: "100%", minHeight:"50px", marginTop:"50px"}}><PageAlert id="student-error" className="student-error" /></div>
        </div>
      </form>
  );
}

export default StudentDetail;
