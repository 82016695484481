import React, {useContext} from 'react';
import Information from './../sdk/information/information';
import { Broadcast } from './../sdk/broadcast';

import './profilePicture.css';

function ProfilePicture({style, className}){
   const {broadcast} = useContext(Broadcast);
   const user = broadcast.get('loggedInUser');
   React.useEffect(()=>{
	},[broadcast.get('loggedInUser')]);

    const getInitialName = ()=>{
    	return user.name.split(" ").reduce((res, item)=>(res + item[0].toUpperCase()),'');
    }

   return (
    	<div style={{...style, backgroundImage: (user.picture?('url('+user.picture+')'):'none')}} className={"row-flex center-flex profile-picture " + (className||"")}>
    		<div>{user.picture?'':getInitialName()}</div>
    	</div>
  );
}

export default ProfilePicture;
