import React, {useEffect} from 'react';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import CircularProgress from '@material-ui/core/CircularProgress';
import Information from "./../sdk/information/information";
import Icon from '@material-ui/core/Icon';
import Checkbox from '@material-ui/core/Checkbox';
import { Button } from '@material-ui/core';
import { Broadcast } from "./../sdk/broadcast";
import SDKTable from './../sdk/sdkTable/sdkTable';
import SessionResource from './../resources/sessionResource';
import StudentResource from './../resources/studentResource';
import VisibilityIcon from '@material-ui/icons/Visibility';
import StudentRow from './studentRow';

import {data} from './config';
const sessionResource = new SessionResource();
const studentResource = new StudentResource();
let {studentHeaders} =  data;
const SessionRow = (props)=>{
   const {broadcast} = React.useContext(Broadcast);
	const { row, fields } = props;
    const [open, setOpen] = React.useState(false);
	const [loadingContent, setLoadingContent] = React.useState(false);   
	const [subTable, setSubtable] = React.useState(null);
	const loggedInUser = broadcast.get("loggedInUser");

    const onCollage = async()=>{
    	setOpen(!open);
    }

    const getSubTable = ()=>{
    	if(!open) return <React.Fragment/>;
    	let initialFilter = {_id: (row.students||[]).map(item=>item._id).join(",")}
    	if(!initialFilter._id) return <React.Fragment/>;
    	return (
    		<SDKTable 
		    	rowId={"_id"}
		    	tableHeaders={studentHeaders} 
		    	refreshChannel={"refreshStudentTable"}
		    	defaultOrder={{name:'studentName', value:'asc'}}
		    	resource={studentResource}
		    	initialFilter={initialFilter}
		    	hasSearch="1"
		    	minHeight={100}
		    	metaData={{students:row.students}}
		    >
		    	<StudentRow/>
		    </SDKTable>
    	)
    }

    const getError = (error)=>{
    	return <div style={{color: "red",width: "100%", position: "absolute", textAlign: "center", marginTop: "20px"}}>{"Error: " + error}</div>
    }

    React.useEffect(()=>{
    	if(loggedInUser && loggedInUser.userRole==2)  studentHeaders = [{id: "instructorName", label: "Instructor Name", type:'string' },...data.studentHeaders];
    },[]);

    return (
    	<React.Fragment>
    		<TableRow  key={props.index} role="checkbox">
		        <TableCell style={{width: "150px"}}>
		        	<React.Fragment>{
			        	row.students &&row.students.length?
			          	(<IconButton aria-label="expand row" size="small" onClick={() =>onCollage()}>
			            	{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
			          	</IconButton>):
			          	<IconButton className="iconButtonHolder" disabled/>
			      	}</React.Fragment>

			        <IconButton className="table-action" aria-label="expand row" size="small" onClick={()=>props.onAction('viewSessionDetail', props.row)}>
						<VisibilityIcon/>
					</IconButton>
					<React.Fragment>
						{loggedInUser.userRole==2?(
							<IconButton className="table-action" aria-label="expand row" size="small" onClick={()=>props.onAction('editSessionDetail', props.row)}>
								<EditIcon/>
							</IconButton>
						):(<React.Fragment/>)} 
					</React.Fragment>
		        </TableCell>
		         <React.Fragment>
		        	{fields.map((field, index)=>(
		        		<TableCell key={index}>{row[field]}</TableCell>
		        	))}
		        </React.Fragment>
		    </TableRow>

		    <React.Fragment>
		    	 
		    		<TableRow className="student-sub-table">
				        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
				          <Collapse in={open} timeout="auto" unmountOnExit>
				            <Box margin={1} >
				              <div className="row-flex">
									<Typography variant="h6" gutterBottom component="div">
						                Assigned Students
						            </Typography>
							  </div>
				              <React.Fragment>
				              	{getSubTable()}
				              </React.Fragment>
				            </Box>
				          </Collapse>
				        </TableCell>
				    </TableRow>
		    </React.Fragment>
    	</React.Fragment>
    );
}

export default SessionRow;
