export default class APIService{
	constructor(serverUrl, storageType){
		this.apiUrl = serverUrl;
		this.path = "";
		if(!storageType) this.storage = localStorage;
		else this.storage = storageType;

	}

	set serverUrl(url){ this.apiUrl = url};

	set tokenKey(key) { this.tk = key };

	set tokenValue(value) { this.storage.setItem(this.tk, value) };

	get tokenValue() { return this.storage.getItem(this.tk) };

	set tokenType(type) { this.storage = type };

	set resource(path) {this.path = path};

	deleteToken(){ this.storage.removeItem(this.tk) };

	getPath(params, postfix=""){
		let path =  this.path + (postfix?`/${postfix}`:'');
		if(!params || typeof(params)!='object') return path; 
		return Object.entries(params).reduce((res, item)=>{
			return res.replace(`:${item[0]}`, item[1]);
		},path);
	}

	async post(params, body, postfix=""){
		let res = await this.request({method: 'POST', path: this.getPath(params, postfix), body: body});
		return res;
	}

	async get(params, body, postfix=""){
		let res = await this.request({method: 'GET', path: this.getPath(params, postfix), body: body});
		return res;
	}

	async put(params, body, postfix=""){
		let res = await this.request({method: 'PUT', path: this.getPath(params, postfix), body: body});
		return res;
	}

	async delete(params, body, postfix=""){
		let res = await this.request({method: 'DELETE', path: this.getPath(params, postfix), body: body});
		return res;
	}


	async request(params){
		let {method, path, body, headers} = params;
		body = this.formatBody(body);
		if(path[0] =='/') path = path.substring(1);
		let url = this.apiUrl + `/${path}`;
		
		if(!headers) headers = {'Content-Type': 'application/json'};
		const token = this.storage.getItem(this.tk);
		if(token) headers['Authorization'] = `Bearer ${token}`;
		let options = {method: method.toUpperCase(), headers}

		if(body && Object.entries(body).length){
		 	if(method =='GET') url = url + "?" + this.queryString(body);
		 	else options.body = JSON.stringify(body);
		}
		
		try{
			let response = await fetch(url, options);
			if(response.status && response.status==401){
			  this.deleteToken();
			  return window.location.href = "/";
			}
			response = await response.json();
			if(response.hasOwnProperty("error_message") || response.hasOwnProperty("error")){
				response.error = response["error"] || response["error_message"];
				return this.handleError(response);
			}
			return this.handleResponse(response); 
		}catch(err){
			console.log(err);
			return this.handleError({error: err.message});
		};
	}

	formatBody(body){
		return body;
	}

	handleResponse(data){
		return data;
	}

	handleError(data){
		return data;
	}

	queryString(body){
		return Object.entries(body).map(([key, value])=>{
			return `${key}=${encodeURIComponent(typeof(value)=="Object"?JSON.stringify(value):value)}`
		}).join("&");
	}
}