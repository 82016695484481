import SDKForm from './../sdk/sdkForm';
const basicStudentForm = {
	...SDKForm,
	'studentName': {label: "Student Name", is_required: true, icon: 'face', description: 'This helps us in pairing your kid with similar age kids for awesome learning'},
	'studentAge': {
		label: "Student Age", 
		is_required: true,
		validation: function(val){
			this.error = '';
			if(parseInt(val)!=val) return this.error ='Invalid Number';		
			if(val<7 || val>16) return this.error = 'The class sessions are only available for students from 7 to 16 year olds';
		}
	},
	'studentEmail': { 
		label: "Student Email", 
		error: '', 
		validation: function(val){
			this.error = '';
				if(!(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test((val||"").toLowerCase()))
			this.error = 'Invalid Email';
		}
	},
	'createdDate': {}
}

export default basicStudentForm;