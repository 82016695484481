import React from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Broadcast } from "./../broadcast";

import  "./breadCrumbs.css";

const BreadCrumbs = (props)=>{
  	const {broadcast} = React.useContext(Broadcast);
  	const[crumbs, setCrumbs] = React.useState([]);
  	React.useEffect(()=>{
  		let pathName = window.location.pathname||"";
		if(pathName[pathName.length-1]=="/") pathName = pathName.substring(0, pathName.length-1);
		if(pathName[0]=="/") pathName = pathName.substring(1);

		setCrumbs(pathName.split("/").reduce((res, key, index, arr)=>{
			res.push({
				path: index==(arr.length-1)?"":(((res[index-1]||{}).path||"") + "/" + key),
				label: (props.labelsObject||{})[key]||(key.charAt(0).toUpperCase() + key.slice(1))
			});
			return res;
		}, []));
  	},[broadcast.get("activePage")]);
	
	return (
		<div className="sdk-breadcrumbs">
				<Breadcrumbs separator={<NavigateNextIcon fontSize="medium" />} aria-label="breadcrumb">
					{crumbs.map((item, index)=>(
						item.path?(<Link color="inherit" href={item.path} key={index}>{item.label}</Link>):(<Typography color="textPrimary" key={index}>{item.label}</Typography>)
					))}
			     </Breadcrumbs>
	    </div>
	)
}

export default BreadCrumbs;
