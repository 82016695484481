import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ProfilePicture from './profilePicture/profilePicture';
import IconButton from '@material-ui/core/IconButton';
import { Broadcast } from './sdk/broadcast';
import Resource from './resources/resource';

const resource = new Resource();
function ProfileMenu(){
	const {broadcast} = React.useContext(Broadcast);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const loggedInUser = broadcast.get("loggedInUser");
	 const handleClick = (event) => {
	    setAnchorEl(event.currentTarget);
	  };

	  const handleClose = () => {
	    setAnchorEl(null);
	  };

	  const logout = ()=>{
	  	resource.deleteToken();
	  	broadcast.delete("loggedInUser");
	  	window.location.href ="/";
	  	setAnchorEl(null);
	  }
	return (
		<React.Fragment>
			<IconButton aria-controls="profile-menu" aria-haspopup="true" onClick={handleClick}>
				<ProfilePicture  style={{fontSize: "20px", width: "35px", height:"35px"}}/>
		    </IconButton>
		    <Menu
		        id="profile-menu"
		        className="profile-menu"
		        anchorEl={anchorEl}
		        keepMounted
		        open={Boolean(anchorEl)}
		        onClose={handleClose}
		        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    			transformOrigin={{ vertical: "top", horizontal: "center" }}
    			getContentAnchorEl={null}
		      >
		      	<div className="column-flex" style={{minWidth: "150px"}}>
			      	<MenuItem>{loggedInUser.name}</MenuItem>
        			<MenuItem onClick={logout}>Logout</MenuItem>
		      	</div>
		    </Menu>
		</React.Fragment>
	);
}

export default ProfileMenu;