export { data, style };
const data ={
		headers:[
			{id: "studentName", label: "Student Name", "roles": [0,1,2], type:'string' },
			{id: "course", label: "Course", "roles": [0,1,2], type:'string' },
			{id: "level", label: "Level", "roles": [0,1,2], type:'number' },
			{id: "sessionDateTime", label: "Session Time", "roles": [0,1,2], type:'string' },
			{id: "instructorName", label: "Instructor Name", "roles": [0,2], type:'string' },
			{id: "createdDate", label: "Created Date", "roles": [2], type:'date' },
			{id: "email", label: "Parent Email", "roles": [2], type:'string' },
			{ type:'break', "roles": [2]},
			{id: "isActive", label: "Active Student", roles: [2], type: 'boolean', hidden:true, default: true}
		],
		hasSearch:true,
		searchBarActions:[
			{id: "missedAttendance", label: "Missed Attendance", roles: [1, 2], isBatch: true, icon:'announcement' },
			{id: "studentSession", label: "Create Session Feedback", roles: [1, 2], isBatch: true, icon:'assignment' },
			{id: "assignSessions", label: "Assign Session Feedbacks", roles: [2], isBatch: true, icon:'assignment_turned_in' },
			{id: "unassignSessions", label: "Unassign Session Feedbacks", roles: [2], isBatch: true, icon:'assignment_late' },
			{id: "deleteStudents", label: "Delete Students", roles: [2], isBatch: true, icon:'delete_forever' },
		],
		sessionHeaders:[
			{id: "course", label: "Course", type:'string' },
			{id: "date", label: "Date", type:'string' },
			{id: "sessionName", label: "Session Name",  type:'string' },
			{id: "attendantStatus", label: "Attendant Status", type:'string', noSearch: true, noSort:true },
		],


		sessionFeedback:{
			course:["Scratch", "Python", "App Lab", "Java"],
			sessionOverallRating: ["Strongly Disagree", "Disagree", "Neutral", "Agree", "Strongly Agree"],
			studentHomeworkStatus: ["Yes, the student completed it", "No, the student didn't complete it", "The student tried, but wasn't able to complete it", "No homework was assigned to student"],
			studentHomeworkFeedback: ["Yes", "No", "There was no homework pdf in the document"],
			sessionMaterialFeedback: [
				"Yeah, I faced no hiccups after reading the document", 
				"Yes, but few things need more explanation", 
				"No, I had to improvise"
			],
			sessionTimeCompletions:[
				"Less than 30 mins",
				"30-45 mins",
				"45-50 mins",
				"more than 50 mins"
			],
			sessionDifficultyFeedback: [
				"It is challenging for them, but they're able to understand and apply the concept on their own", 
				"They are understanding the concept but need practice", 
				"They are able to complete the code on their own with no help",
				"They're not able to understand anything and are parroting what I instruct"
			]
		}	
	},
	style = {
	
	}