import React, {useContext} from 'react';
import { Broadcast } from './../sdk/broadcast';
import queryString from 'query-string';
import Resource from './../resources/resource';
import Login from './../login/login';
import Loading from './../sdk/loading/loading';
import PageAlert from './../sdk/pageAlert/pageAlert';
import './authentication.css';

const resource = new Resource();
let query = {};
if(window.location.search){
    query = queryString.parse(window.location.search);
    if(query["token"]){
      resource.tokenValue = query["token"];
      setTimeout(()=>{
        window.location.href = window.location.origin + window.location.pathname;
      }, 1500)   
    } 
}

function Authentication({children}){ 
  console.log("authentication");
   const {broadcast} = useContext(Broadcast);
   const [user, setUser] = React.useState(broadcast.get('loggedInUser'));
   	React.useEffect(()=>{
	    authenticating();
	     
  },[]);


  const authenticating = async()=>{
   	if(query['callback_error']){
      const err = query['callback_error'];
      return broadcast.set("login_alert", {message: (err=='User not found')?"No class found for this user, please register for a class at the home page":err, type:"error"});
    }

    if(query['callback_result'])
      return broadcast.set("login_alert", {message: query['callback_result'], type:"success"});
    

      if(broadcast.get("loggedInUser")) return true;
   		if(!resource.tokenValue) return false;
   		broadcast.set("loading", true);
   		let res = await resource.userInfo();
   		broadcast.set("loading", false);
   		if(res._id){
         broadcast.set("loggedInUser", res);
        setUser(res);
         return true;
      }
   		resource.deleteToken();
   		if(res.error) return broadcast.set("login_alert", {type:"error", message: res.error});
   		return broadcast.set("login_alert", {type:"error", message: "No class found for this user, please register for a class at the home page"});
  }

   return (
    	<React.Fragment>
    		{user?(children):
    			(
    				<React.Fragment>
		              <Loading/>
		              <Login />
		              <PageAlert id ="login_alert" className="login_alert" /> 				
		            </React.Fragment>
    			)
    		}
    	</React.Fragment>
  );
}

export default Authentication;
