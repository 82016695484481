import React, {useContext} from 'react';
import { Broadcast } from './../sdk/broadcast';
import PasswordFlow from './passwordFlow/passwordFlow';
import NewAccount from './newAccount/newAccount';
import ForgetPassword from './../resetPassword/forgetPassword';
import Information  from './../sdk/information/information';
import { Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import LoginResource from './../resources/loginResource';
import './login.css';

const resource = new LoginResource();

const Login =()=>{
   const {broadcast} = useContext(Broadcast);
   const [loginAction, setLoginAction] = React.useState();
   React.useEffect(()=>{
	},[]);

   const renderAction = (action)=>{
   		if(!action) 
	   		return (
	   			<React.Fragment>
					<Button variant="contained" style={{color:'white', width: '100%', backgroundColor:'#629bf1'}} onClick={()=>submit('token', 'GoogleOAuth2')}>Login with Google Account</Button>
					<Button variant="contained" style={{color:'white', width: '100%', backgroundColor:'#629bf1'}} onClick={()=>submit('token', 'YahooOAuth2')}>Login with Yahoo Account</Button>
					<div style={{textAlign: "center", margin: "10px 0"}} >OR</div>
					<br/>
					<PasswordFlow submit={submit}/>
					<div className="row-flex">
						<div>No Account? <a onClick={(e)=>{e.preventDefault();setLoginAction(1);}} href="">Create One</a></div>
						<div className="free-space"/>
						<div><a onClick={(e)=>{e.preventDefault();setLoginAction(2);}} href="">Forgot Password</a></div>
					</div>
				</React.Fragment>
	   		)
	   	if(action==1)
	   		return (
	   			<React.Fragment>
		   			<NewAccount submit={submit}/>
		   			<div style={{textAlign: "center"}}><a onClick={(e)=>{e.preventDefault();setLoginAction();}} href="">Back</a></div>
		   		</React.Fragment>
	   		)

	   	return (
	   		<ForgetPassword onCancel={()=>setLoginAction()}></ForgetPassword>
	   	);
   }

   const submit = async (action, type, data={})=>{
	   	handleError("");
	   	broadcast.set("loading", true);
	   	const res = await resource.post(null, {type, email: data.email, password: data.password}, action);
	   	broadcast.set("loading", false);
	   	if(res.error) 
	   		return handleError(res.error);
	   	if(res.redirect_url) 
	   		return window.location.href = res.redirect_url;
	   	if(res.access_token){
	   	    resource.tokenValue = res.access_token;
	   		return window.location.href = window.location.origin + window.location.pathname; 
	   	}
	   	if(res.success){
	   		setLoginAction();
	   		return broadcast.set("login_alert", {type:"success", message:"Congratulation! your account has been successfully signed up"});
	   	}
   }


   const handleError = (error)=>{
   		console.log("handleError")
   		broadcast.set("login_alert", {type:"error", message:(typeof(error)=="string"?error:JSON.stringify(error))});
   }

   return (
   		<Information id="login-id">
   			{resource.tokenValue?<React.Fragment/>:
   				<div className="login page-padding column-flex center-flex" >
	   				<div className ="login-container">
	   					
			   					<div className="row-flex center-flex" style={{height: "200px", backgroundColor:"black", width:"200px", margin: "auto"}}>
			   						<img src={require("./../assets/logo_black_white2.jpg")} style={{height:"80px"}} />
			   					</div>
			   					 <br/>
			   					 <br/>
			   					<React.Fragment>{renderAction(loginAction)}</React.Fragment>
	    			</div>
	    		</div>}
  		</Information>
  );
}

export default Login;
