/******************************************
  For redirection, config can be supplied with either path or component. 
  If using path, expect the whole page will be reloaded
* title
* key: unique identity
* path: path to components
* subMenu: [ {title, path, component....}]
*******************************************/

export const config = ()=>{
	return [
		{
			title: "Personal Info",
			key: "profile",
			path: "/profile",
			icon: "perm_contact_calendar",
			role: 0
		},
		{
			title: "Users",
			key: "users",
			path: "/users",
			icon: "people",
			role: 2

		},
		{
			title: "Students",
			key: "students",
			path: "/students",
			icon: "view_list",
			role: 0
		},
		{
			title: "Sessions",
			key: "sessions",
			path: "/sessions",
			icon: "assignment",
			role: 1
		}
	];
};