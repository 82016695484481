import React, {useContext} from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';

import './loading.css';
import { Broadcast } from './../broadcast';

function Loading(props) {
  const {broadcast} = useContext(Broadcast);
  const isLoading = broadcast.get(props.id||"loading");
  React.useEffect(()=>{
  },[])
  return (
	<React.Fragment>
		{isLoading?
			(props.loadingType=="circular"?
				(<div style={{width:"100%"}} className="row-flex center-flex"><CircularProgress className={props.className||"app-loading"} /></div>):
				<LinearProgress className={props.className||"app-loading"} />
			):<div style={{height: "4px"}}/>
		}
	</React.Fragment>    	
  );
}

export default Loading;
