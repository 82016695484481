import SDKForm from './../../sdk/sdkForm';
export {data, style};
const data = {
		form: {		
					...SDKForm,
				    'email': { 
				    	label: "Email", 
				    	is_required: true, 
				    	error: '', 
				    	icon: 'mail',
						validation: function(val){
							this.error = '';
							if(!(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test((val||"").toLowerCase()))
							this.error = 'Invalid Email';
						}
					},
					'password': {
						label: "Password", 
						is_required: true,
						validation: function(val){
							this.error = '';
							if(!(/(?=.*[a-z])/).test(val)) return this.error ='Password must have at least 1 lowercase ';
							if(!(/(?=.*[A-Z])/).test(val)) return this.error ='Password must have at least 1 uppercase letter';
							if(!(/(?=.*\d)/).test(val)) return this.error ='Password must have at least 1 number';
							if(!(/(?=.*[\W])/).test(val)) return this.error ='Password must have at least 1 special character';
							if(val.length<8) return this.error ='Password must have at least 8 characters';

						}
					},
					'retypePassword': {
						label: "Retype Password", 
						is_required: true
					}
			}
	},
	  style= {

	  }	