import React, {useEffect} from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import UserResource from './../resources/userResource';
import { Broadcast } from "./../sdk/broadcast";
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import { Button } from '@material-ui/core';

import UserRow from './userRow';

const userResource = new UserResource();

const useStyles = makeStyles({
  container: {
    minHeight: 500,
    width: '100%',
    position: 'relative'
  }
});

const UserTable = (props)=>{
    const classes = useStyles();
	const {broadcast} = React.useContext(Broadcast);
	const [loadingContent, setLoadingContent] = React.useState(false);   
	const [pagination, setPagination] = React.useState({offset: null, limit: 10});
	const [table, setTable] = React.useState(null);
	const getTable = ()=>{
		if(!table) return <TableBody></TableBody>
		if(table.error) return <div style={{color: "red",width: "100%", position: "absolute", textAlign: "center", marginTop: "20px"}}>{"Error: " + table.error}</div>
		return (
			<TableBody>
	          {table.map((row, index) => (
	            <UserRow row={row} key={index} onAction={props.onAction}/>
	          ))}
	        </TableBody>
	    );
	}

	const getUsers = async()=>{
		setTable(null);
		setLoadingContent(true);
		const res = await userResource.get(null);
		setTable(res);
		setLoadingContent(false);
	}

	React.useEffect(()=>{
		getUsers();
	}, [broadcast.get('refreshUserTable'), pagination]);

	
	return (
		<React.Fragment>
			<div>{loadingContent?<LinearProgress/>:<React.Fragment/>}</div>
			<div className="row-flex">
				<div className="free-space"/>
				<Button onClick={()=>props.onAction('onNewUserForm')} className="table-action"><Icon>add</Icon>New User</Button>

			</div>
			<TableContainer component={Paper} className={classes.container}>
		      <Table aria-label="collapsible table" stickyHeader >
		        <TableHead>
		          <TableRow>
		            <TableCell />
		            <TableCell >Name</TableCell>
		            <TableCell >Email</TableCell>
		            <TableCell >Phone Number</TableCell>
		            <TableCell >User Role</TableCell>
		          </TableRow>
		        </TableHead>  
		        <React.Fragment>{getTable()}</React.Fragment>
		      </Table>

		    </TableContainer>
	    </React.Fragment>
	);
}

export default UserTable;
