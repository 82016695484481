import React, {useContext} from 'react';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import Edit from '@material-ui/icons/Edit';
import userForm from './../forms/user';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import { Button } from '@material-ui/core';
import Resource from './../resources/resource';
import { Broadcast } from './../sdk/broadcast';

let formData = {...userForm};
delete formData.email;
delete formData.userRole;
delete formData.phoneNumber;
delete formData.name;
export default function UpdateEmail({user, onCancel}){
	const {broadcast} = React.useContext(Broadcast);
	const [form, setForm] = React.useState({...formData});
	const handleChange = (id, value, type)=>{
		if(id=='notificationType'){
			if(!value)
				form.onChange(id, form[id].value.filter(v=>v!=type));
			else{
				if(form[id].value.indexOf(type)==-1) form[id].value.push(type);
				form.onChange(id, [...form[id].value]);
			}
		}
		else
	    	form.onChange(id, value);
	    setForm({...form});
	  }

	 const onSubmit = async()=>{
	 	const resource = new Resource("users/self");
		let res = await resource.put(null, form.values());
		if(res.error) return broadcast.set("reset_password", {type:"error", message: res.error});
		return window.location.reload();
	 }
	
	React.useEffect(()=>{
		form.clear();
      	form.setValues(user);

      	setForm({...form});
		if(!form.notificationType.value) form.notificationType.value = [0];
	},[]);

	return(
		<form noValidate autoComplete="off">
			<div className="field-container row-flex">
				<div className="app-description field-label">Email</div>
				    <strong>{user.email}</strong>
			</div>
			<div className="field-container field-container-form row-flex">
				<div className="app-description field-label">{form.secondaryEmail.label}</div>
					<TextField 
						className="free-space" 
						required = {form.secondaryEmail.is_required}
					   error = {form.secondaryEmail.error?true:false}
					   id="secondaryEmail"
					   value={form.secondaryEmail.value || ""}
					   helperText={form.secondaryEmail.error||""}
					   variant="outlined"
					   onChange={(event)=>handleChange('secondaryEmail', event.target.value)}
					/>
				</div>

				<div className="field-container field-container-form row-flex">
					 <div className="app-description field-label">{form.notificationType.label}</div>
					 	<FormControlLabel
	                      control={
	                        <Checkbox
	                          id="notificationType"
	                          checked={(form.notificationType.value||[]).indexOf(0)>-1}
	                          onChange={(event)=>handleChange('notificationType', event.target.checked, 0)}
	                          name="notificationType"
	                          color="primary"
	                        />
	                      }
	                      label="Primary Email"
	                    />

	                    <FormControlLabel
	                      control={
	                        <Checkbox
	                          id="notificationType"
	                          checked={(form.notificationType.value||[]).indexOf(1)>-1}
	                          onChange={(event)=>handleChange('notificationType', event.target.checked, 1)}
	                          name="notificationType"
	                          color="primary"
	                        />
	                      }
	                      label="Secondary Email"
	                    />
	                 </div>

				

				<div style={{width:'100%', margin: '10px'}} className="row-flex center-flex">
			  		<React.Fragment>
						<Button  style={{width: "120px"}}  onClick={onCancel}>Cancel </Button> 
				    	<div style={{width: "20px"}}></div>
				    	<Button variant="contained" 
				        	disabled = {form.hasError()}
				        	style={{color:'white', minWidth: '120px', backgroundColor: form.hasError()?'#e5e5e5':'#629bf1'}}
				        	onClick={onSubmit}>
				        	Submit
				    	</Button>
				 	</React.Fragment>
			  </div>
		</form>	
	)}

