import SDKForm from './../sdk/sdkForm';

const form = {
		...SDKForm,
		'password': {
						label: "New Password", 
						is_required: true,
						validation: function(val){
							this.error = '';
							if(!(/(?=.*[a-z])/).test(val)) return this.error ='Password must have at least 1 lowercase ';
							if(!(/(?=.*[A-Z])/).test(val)) return this.error ='Password must have at least 1 uppercase letter';
							if(!(/(?=.*\d)/).test(val)) return this.error ='Password must have at least 1 number';
							if(!(/(?=.*[\W])/).test(val)) return this.error ='Password must have at least 1 special character';
							if(val.length<8) return this.error ='Password must have at least 8 characters';

						}
					},
		'retypePassword': {
			label: "Retype Password", 
			is_required: true
		}
	},
	
	style= {

	 }	

export {form, style};
