import React, {useContext} from 'react';
import { Broadcast } from './../broadcast';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Alert from '@material-ui/lab/Alert';
import './pageAlert.css';


function PageAlert(props){
   const {broadcast} = useContext(Broadcast);
   const [ alert, setAlert ] = React.useState({});   
   React.useEffect(()=>{
   		const tmp = broadcast.get(props.id||"alert") || {};
      console.log(tmp);
   		setAlert(tmp);
   },[broadcast.get(props.id||"alert")]);
   return (
    	<Collapse in={alert.message?true:false} className={props.className||"alert-container"}>
                    <Alert
                      severity={alert.type}
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setAlert({...alert, message:""});
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }

                    >
                      <span style={{textTransform: "capitalize"}}>{ alert.type + ": "}</span> 
                      <span>{alert.message}</span> 
                    </Alert>
        </Collapse>
  );
}

export default PageAlert;
