import React, {useEffect} from 'react';
import Dialog from "@material-ui/core/Dialog";
import Drawer from '@material-ui/core/Drawer';

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";

import WindowResize from './../sdk/windowResize';

import UserResource from './../resources/userResource';

import StudentResource from './../resources/studentResource';

import { Broadcast } from "./../sdk/broadcast";
import Information from "./../sdk/information/information";
import UserTable from './userTable';
import UserDetail from './userDetail';
import StudentDetail from './studentDetail';
import Confirmation from './../sdk/confirmation/confirmation';

import {data} from './config';
import  SDKTable from './../sdk/sdkTable/sdkTable';
import UserRow from './userRow';

import './users.css';

const userResource = new UserResource();

const studentResource = new StudentResource();

const {headers, hasSearch, searchBarActions}  = data;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const Users = ()=> {	
     console.log("Users");

  const size = WindowResize();
  const {broadcast} = React.useContext(Broadcast);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [formContent, setFormContent] = React.useState(<React.Fragment/>);

  const onTableAction = (actionName, data)=>{
  		tableAction[actionName](data);
  }

 
  const tableAction = {
  	   onNewUserForm: ()=>{
  	   	 broadcast.delete("user-error");   	
	  	setFormContent(<UserDetail onAction={userAction.newUser}  onCancel={userAction.cancel}/>);
	  	setOpenDialog(true);
	  },

	  onEditUserForm: (data)=>{
	  	 broadcast.delete("user-error");   	
	  	setFormContent(<UserDetail data={data} onAction={userAction.updateUser} onCancel={userAction.cancel}/>);
	  	setOpenDialog(true);
	  },

	   onNewStudentForm: (data)=>{
  	   	broadcast.delete("student-error");   	
	  	  setFormContent(<StudentDetail onAction={userAction.newStudent}  user={data} onCancel={userAction.cancel}/>);
	  	  setOpenDialog(true);
	  },

	  onEditStudentForm: (data)=>{
	  	let user = {email: data.email};
	  	broadcast.delete("student-error");   	
	  	setFormContent(<StudentDetail onAction={userAction.updateStudent}  user={user} data={data} onCancel={userAction.cancel}/>);
	  	setOpenDialog(true);
	  },

    onDeleteStudentForm: (data)=>{
      broadcast.delete("student-error");    
      setFormContent(
        <Confirmation 
          title="Delete Students" 
          onSubmit={userAction.deleteStudent} 
          onCancel={userAction.cancel} 
          data={data}
          loadingChannel="delete-students-loading"
          errorChannel="delete-students-error"
          confirmString="DELETE"
        >
          <div>{"Are you sure you want to delete student "+ data.studentName + "?"}</div>

        </Confirmation>
       );
      setOpenDialog(true);
    },
   }

   const onDialogClose = ()=>{
   		setFormContent(<React.Fragment/>)
   		setOpenDialog(false);
   }

   const userAction = {
   		updateUser: async(data, callback)=>{
   			broadcast.delete("user-error");   	
   			let res = await userResource.put(null, data, data._id);
        callback();
   			if(res.error) return broadcast.set("user-error", {message: res.error, type:"error"});
   			//onDialogClose();
   			setConfirmationContent("", "User has been updated", null, onDialogClose);

        return broadcast.set("refreshUserTable", Date.now());
   		},
   		newUser: async (data, callback)=>{
 			broadcast.delete("user-error");   	
   			let res = await userResource.post(null, data);
        callback();
   			if(res.error) return broadcast.set("user-error", {message: res.error, type:"error"});
   			//onDialogClose();
   			setConfirmationContent("", "User has been created", null, onDialogClose);
        return broadcast.set("refreshUserTable", Date.now());
   		},
   		newStudent: async (data, callback)=>{
   			console.log("newStudent");
   			broadcast.delete("student-error");   	
   			let res = await studentResource.post(null, data);
        callback();
   			if(res.error) return broadcast.set("student-error", {message: res.error, type:"error"});
 			  //onDialogClose();
 			  setConfirmationContent("", "New student has been created", null, onDialogClose);
        return broadcast.set("refreshUserTable", Date.now());
   		},

   		updateStudent: async (data, callback)=>{
   			broadcast.delete("student-error");   	
   			let res = await studentResource.put(null, data, data._id);
        callback();
   			if(res.error) return broadcast.set("student-error", {message: res.error, type:"error"});
   			//onDialogClose();
   			setConfirmationContent("", "Student has been updated", null, onDialogClose);
        return broadcast.set("refreshUserTable", Date.now());
   		},

      deleteStudent: async (data)=>{
        broadcast.delete("delete-students-loading");
        broadcast.delete("delete-students-error");    
        broadcast.set("delete-students-loading", true);
        let res = await studentResource.delete(null, null, data._id);
        broadcast.set("delete-students-loading", false);
        if(res.error)
          return broadcast.set("delete-students-error", {message: res.error, type:"error"});
        setConfirmationContent("", "Student has been deleted", null, onDialogClose);
        return broadcast.set("refreshUserTable", Date.now());
      },

   		cancel: ()=>{
   			onDialogClose();
   		}

   }

   const setConfirmationContent = (title, children, onSubmit, onCancel, data=[], loadingChannel="", errorChannel="")=>{
      setFormContent(
      <Confirmation 
        title={title}
        onSubmit={onSubmit} 
        onCancel={onCancel} 
        data={data}
        loadingChannel={loadingChannel}
        errorChannel={errorChannel}
      >
        <div>{children}</div>
      </Confirmation>
    );
   }

   React.useEffect(()=>{
      const loggedInUser = broadcast.get('loggedInUser');
      if(!loggedInUser || loggedInUser.userRole!==2) return window.location.href ="/";
   }, [broadcast.get('loggedInUser')]);


  return (
  	<div className="page-padding">
    	<SDKTable 
        tableHeaders={headers} 
        rowId={"_id"}
        onTableAction={onTableAction}
        onRowAction={onTableAction}
        refreshChannel={"refreshUserTable"}
        defaultOrder={{name:'name', value:'asc'}}
        resource={userResource}
        batchActions = {searchBarActions}
        inlineAction={true}
        hasSearch={true}
      >
        <UserRow/>
      </SDKTable>
    	<React.Fragment>
	    	{(size.width<=850)?(
		  		<Dialog
			  		className="form-dialog"
			        disableEscapeKeyDown={true}
			        open={openDialog}
			        onClose={evt=>onDialogClose()}
			        TransitionComponent={Transition}
			      >	
			      	<div className="row-flex center-flex" style={{height: "100%", width:"100%"}}>
			      		<div style={{width:  size.width>=850?'70%':'90%'}}>{formContent}</div>
			      	</div>
			    </Dialog>):(
			    <Drawer 
			     	anchor='right' 
			     	open={openDialog} 
			     	onClose={evt=>onDialogClose()}
			      >
			      	<div className="row-flex center-flex" style={{height: "100%", width: "650px"}} >
			      		<div style={{width:  size.width>=850?'70%':'90%'}}>{formContent}</div>
			      	</div>
		        </Drawer>)
		  	}
	    </React.Fragment>
    </div>
    
  );
}

export default Users;
