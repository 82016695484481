import React, {useContext} from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import Information from './../sdk/information/information';
import { Broadcast } from './../sdk/broadcast';
import ProfilePicture from './../profilePicture/profilePicture';
import PersonalSummary from './personalSummary';
import ClassSummary from './classSummary';

import './profile.css';

function Profile(){
  console.log("profile")
   const {broadcast} = useContext(Broadcast);
   const user = broadcast.get('loggedInUser');
 

   return (
    	<div className="profile">
    		<div className ="profile-summary">
	    		<div className="row-flex center-flex">
	    			<ProfilePicture  style={{fontSize: "50px", width: "100px", height:"100px"}} className="personal-info-image"/>
	    		</div>
	    		<div className="row-flex center-flex">
	  				<Typography variant="h5" component="h2">{user.name}</Typography>
	    		</div>
	    		<div className="row-flex center-flex">
	  				<div className="app-description">{user.email}</div>
	    		</div>
    		</div>
    		<br/>
    		<br/>
    		<div className="row-flex center-flex">
    			<PersonalSummary user={user} />
    		</div>
    		<br/>
    		<br/>
    	
    		<br/>
    		<br/>
    	</div>
  );
}

export default Profile;
