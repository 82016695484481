import APIService from './../sdk/apiService';

export default class Resource extends APIService{
	constructor(resource){
		super(process.env.REACT_APP_SERVER_URL);
		this.path = resource;
		this.tk = "session_token";
	}

	async userInfo(){
		let res = this.request({method: "GET", path: "users/self"});
		return res;
	}

	formatBody(body){
		if(!body) return;
		if(body.offset && typeof(body.offset)=="object") body.offset = btoa(JSON.stringify(body.offset));
		return body;
	}
}