import React, {useContext} from 'react';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import PageAlert from './../sdk/pageAlert/pageAlert';
import { Broadcast } from './../sdk/broadcast';
import Resource from './../resources/resource';
import Typography from '@material-ui/core/Typography';

import ResetPassword from './resetPassword';
import { form, style } from './config';

function ForgetPassword(props) {
  const {broadcast} = React.useContext(Broadcast);
  const [_form, _setForm] = React.useState({
      ...form, 
      'email': { 
              label: "Email", 
              is_required: true, 
              error: '', 
              icon: 'mail',
            validation: function(val){
              this.error = '';
              if(!(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test((val||"").toLowerCase()))
              this.error = 'Invalid Email';
            }
          }
    });
  
  const handleChange = (id, value)=>{
    _form.onChange(id, value);
    if( id.toLowerCase().indexOf("password")>-1 && 
      !_form["password"].error && 
      !_form["retypePassword"].error && 
      _form["password"].value != _form["retypePassword"].value) 
      _form["retypePassword"].error = "Password doesn't match"
    _setForm({..._form});
  }

  const onSubmit = async ()=>{
        broadcast.set("login_alert", {message:""});

    const resource = new Resource("oauth2/registerResetPassword");
    const values = _form.values();
    const loggedInUser = broadcast.get("loggedInUser");
    let res = await resource.post(null, {redirectUrl: window.location.origin + window.location.pathname, password: values.password, email: values.email});
    if(res.error) return broadcast.set("login_alert", {type:"error", message: res.error});
    broadcast.set("login_alert", {type:"success", message:"We have sent you an email to follow up with the reset password process. Check your spam folder if you have not received it"});
    return onCancel();
  } 

  const onCancel = ()=>{
    // broadcast.set("login_alert", {type:"error", message: ""});
    _form.clear();
    _setForm({..._form});
    if(props.onCancel) props.onCancel();
  }

  return (
    <React.Fragment>
      <form noValidate autoComplete="off" className="row-flex center-flex">
        <Typography variant="h4" style={{textAlign:'center', marginBottom:"20px"}}>Forget Password</Typography>
        <div style={{width:'100%'}} className="account column-flex form-container">
            <div>
              <TextField
                style={{width:'100%'}}
                required = {_form.email.is_required}
                error = {_form.email.error?true:false}
                id="email"
                value={_form.email.value || _form.email.default||""}
                label={_form.email.label}
                helperText={_form.email.error||""}
                variant="outlined"
                onChange={(event)=>handleChange('email', event.target.value)}
              />
            </div> 

            <ResetPassword form={_form} handleChange={handleChange}></ResetPassword>
        </div>

         <div style={{width:'100%', marginTop: '10px'}} className="row-flex center-flex">
              <React.Fragment>
                <Button  style={{width: "120px"}}  onClick={onCancel}>Cancel </Button> 
                <div style={{width: "20px"}}></div>
                <Button variant="contained" 
                        disabled = {_form.hasError()}
                        style={{color:'white', minWidth: '120px', backgroundColor: _form.hasError()?'#e5e5e5':'#629bf1'}}
                        onClick={onSubmit}>
                        Submit
                </Button>
              </React.Fragment>
          </div>
      </form>
    </React.Fragment>
  );
}

export default ForgetPassword;
