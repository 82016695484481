import SDKForm from './../../sdk/sdkForm';
export {data, style};
const data = {
		form: {		
					...SDKForm,
				    'email': { 
				    	label: "Email", 
				    	is_required: true, 
				    	error: '', 
				    	icon: 'mail',
						validation: function(val){
							this.error = '';
							if(!(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test((val||"").toLowerCase()))
							this.error = 'Invalid Email';
						}
					},
					'password': {label: "Password", is_required: true}
			}
	},
	  style= {

	  }	