import React, {useEffect} from 'react';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import CircularProgress from '@material-ui/core/CircularProgress';
import Information from "./../sdk/information/information";
import Icon from '@material-ui/core/Icon';
import Checkbox from '@material-ui/core/Checkbox';
import { Button } from '@material-ui/core';
import { Broadcast } from "./../sdk/broadcast";
import SDKTable from './../sdk/sdkTable/sdkTable';
import SessionResource from './../resources/sessionResource';
import {data} from './config';

import SessionRow from './sessionRow';

const {sessionHeaders} =  data;
const sessionResource = new SessionResource();
const StudentRow = React.forwardRef((props, ref)=>{
   const {broadcast} = React.useContext(Broadcast);
	const { row } = props;
    const [open, setOpen] = React.useState(false);
	const [loadingContent, setLoadingContent] = React.useState(false);   
	const [subTable, setSubtable] = React.useState(null);
	const [selected, setSelected] = React.useState(props.isSelected);
    const onCollage = async()=>{
    	setOpen(!open);
    }


    const handleClick = ()=>{
    	props.onRowSelect(props.id, props.index, !selected);
    	setSelected(!selected);
    }

    const getSubTable = ()=>{
    	if(!open) return <React.Fragment/>;
    	let initialFilter = {"students._id": (row._id || row.id)}
    	return (
    		<SDKTable 
    			metaData={{studentId:(row._id||row.id)}}
		    	rowId={"_id"}
		    	tableHeaders={sessionHeaders} 
		    	refreshChannel={"refreshSessionTable"}
		    	defaultOrder={{name:'date', value:'desc'}}
		    	resource={sessionResource}
		    	initialFilter= {initialFilter}
		    	hasSearch={1}
		    	inlineAction={1}
		    	minHeight={100}
		    	onRowAction={props.onAction}
		    >
		    	<SessionRow />
		    </SDKTable>
    	)
    }

    const getError = (error)=>{
    	return <div style={{color: "red",width: "100%", position: "absolute", textAlign: "center", marginTop: "20px"}}>{"Error: " + error}</div>
    }

    React.useEffect(()=>{
    	setSelected(false);
    }, [broadcast.get('cancelSelectedStutents')]);

    React.useImperativeHandle(ref, ()=>({
    	toggleSelect: (val)=>setSelected(val)
    }));

    return (
    	<React.Fragment>
    		<TableRow  key={props.index} role="checkbox">
		        <TableCell style={{width: "150px"}}>
		          	<Checkbox checked={selected} onClick={(event) => handleClick()} />
					<IconButton aria-label="expand row" size="small" onClick={() =>onCollage()}>
			            	{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
			        </IconButton>
					<IconButton className="table-action" aria-label="expand row" size="small" onClick={()=>props.onAction('onEditStudentForm', row)}>
						<EditIcon/>
					</IconButton>
		        </TableCell>
		        <TableCell scope="row">{row.studentName}</TableCell>
		        <TableCell scope="row">{row.course}</TableCell>
		        <TableCell scope="row">{row.level}</TableCell>
		        <TableCell scope="row">{row.sessionDateTime}</TableCell>
		        <TableCell scope="row">{row.instructorName}</TableCell>
		        <TableCell scope="row">{row.createdDate}</TableCell>
		        <TableCell scope="row">{row.email}</TableCell>

		    </TableRow>

		    <React.Fragment>
		    	 
		    		<TableRow className="sub-table">
				        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
				          <Collapse in={open} timeout="auto" unmountOnExit>
				            <Box margin={1}>
				              <div className="row-flex">
									<Typography variant="h6" gutterBottom component="div">
						                Student Sessions
						            </Typography>
							  </div>
				              <React.Fragment>
				              	{getSubTable()}
				              </React.Fragment>
				            </Box>
				          </Collapse>
				        </TableCell>
				    </TableRow>
		    </React.Fragment>
    	</React.Fragment>
    );
});


export default StudentRow;
