import React from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import { Button } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import PhoneInput from 'react-phone-input-2';
import userForm from './../forms/user';
import Information from "./../sdk/information/information";
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import PageAlert from './../sdk/pageAlert/pageAlert';
import CircularProgress from '@material-ui/core/CircularProgress';

import 'react-phone-input-2/lib/material.css';


function StudentDetail({data, onAction, onCancel}) {
  const [_form, _setForm] = React.useState({...userForm});
  const [loading, setLoading] = React.useState(false);
   React.useEffect(()=>{
      if(data)
        _form.setValues(data);
      else _form.clear();
      _setForm({..._form});
   }, []);

  const handleChange = (id, value)=>{
    _form.onChange(id, value);
    if(id=='studentAge' && value>=12) _form.onChange('level', 1);
    _setForm({..._form});
  }
  
  const hasError = ()=>{
    return _form.hasError(['name','phoneNumber','email', 'role']);
  }

  const onSubmit = ()=>{
    let item =_form.values();
    if(data){
      const id = data._id;
      Object.keys(item).forEach(key=>{
        if(item[key] == data[key]) delete item[key];
      })     
      delete item.email;
      item._id = id;
    }
    setLoading(true);
    onAction(item, ()=>{
      setLoading(false);
    });
  }

  return (
      <form noValidate autoComplete="off" className="row-flex center-flex">
        <div className="row-flex center-flex" data-aos="flip-down" data-aos-delay="100" data-aos-easing="ease-in-out"><Typography variant="h5">{data?"Edit User":"New User"}</Typography ></div>
        <div style={{width:'100%'}} className="account column-flex form-container">
          <br/>
          <div>
            <TextField
              style={{width:'100%'}}
              required = {_form.email.is_required}
              error = {_form.email.error?true:false}
              id="email"
              value={_form.email.value || _form.email.default||""}
              label={_form.email.label}
              helperText={_form.email.error||""}
              variant="outlined"
              onChange={(event)=>handleChange('email', event.target.value)}
              inputProps={{readOnly:data?true:false }}
            />
          </div>

          <div>
            <TextField
              style={{width:'100%'}}
              required = {_form.name.is_required}
              error = {_form.name.error?true:false}
              id="name"
              value={_form.name.value || _form.name.default||""}
              label={_form.name.label}
              helperText={_form.name.error||""}
              variant="outlined"
              onChange={(event)=>handleChange('name', event.target.value)}
            />
          </div>
            

          <div className={"MuiTextField-root " + (_form.phoneNumber.error?" error-MuiTextField-root":"")}>
              <PhoneInput
                  country={'us'}
                  inputProps={{
                    id: 'phoneNumber',
                    required: _form.phoneNumber.is_required
                  }}
                  onChange={(val)=>handleChange('phoneNumber', val)}
                  defaultErrorMessage= {_form.phoneNumber.error||""}
                  value={_form.phoneNumber.value || _form.phoneNumber.default||""}
                   specialLabel={_form.phoneNumber.label + (_form.phoneNumber.is_required?" *":"")}
              />
              <p className="phoneinput-error">{_form.phoneNumber.error||""}</p>
          </div> 

          <FormControl style={{width:'100%', height: '85px'}} variant="outlined" error = {_form.userRole.error?true:false} required = {_form.userRole.is_required}>
                <InputLabel id="userRole">{_form.userRole.label}</InputLabel>
                <Select
                  native
                  value={_form.userRole.value || _form.userRole.default||0}
                  label={_form.userRole.label}
                    onChange={(event)=>{handleChange('userRole', event.target.value)}}
                >
                  <option value={"0"}>Basic User</option>
                  <option value={"1"}>Instructor</option>
                  <option value={"2"}>Admin</option>

                </Select>
                <FormHelperText>{_form.userRole.error||""}</FormHelperText>
          </FormControl>
          <React.Fragment>{
             loading?(<div style={{width:"100%"}} className="row-flex center-flex"><CircularProgress className="app-loading" /></div>):
             (<div style={{width:'100%', marginTop: '10px'}} className="row-flex center-flex">
                  <Button  style={{width: "120px"}} onClick={()=>onCancel()}>Cancel </Button> 
                   <div style={{width: "20px"}}></div>
                  <Button variant="contained" 
                      disabled = {hasError()}
                       style={{color:'white', minWidth: '120px', backgroundColor: hasError()?'#e5e5e5':'#629bf1'}}
                           onClick={()=>onSubmit(1)}>Submit</Button>
              </div>)
          }
          
          </React.Fragment>
          <div className="row-flex center-flex" style={{width: "100%", minHeight:"50px", marginTop:"50px"}}><PageAlert id="user-error" className="user-error" /></div>

        </div>

      </form>
  );
}

export default StudentDetail;
