import React, {useEffect} from 'react';
import Dialog from "@material-ui/core/Dialog";
import Drawer from '@material-ui/core/Drawer';

import CloseIcon from "@material-ui/icons/Close";

import { Slide, Button, IconButton, Icon } from '@material-ui/core';

import WindowResize from './../sdk/windowResize';

import StudentResource from './../resources/studentResource';
import SessionResource from './../resources/sessionResource';
import Tooltip from '@material-ui/core/Tooltip';


import { Broadcast } from "./../sdk/broadcast";
import Information from "./../sdk/information/information";
// import StudentTable from './studentTable';
import StudentDetail from './studentDetail';
import Confirmation from './../sdk/confirmation/confirmation';

import  StudentSession from './studentSession';
import  AssignSessions from './assignSessions';

import SDKTable from './../sdk/sdkTable/sdkTable';

import StudentRow from './studentRow';
import AdminStudentRow from './adminStudentRow';
import BasicStudentRow from './basicStudentRow';

import { data } from './config';

import './students.css';

const studentResource = new StudentResource();
const sessionResource = new SessionResource();



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

let hasSelect = true;

let tableHeaders = data.headers;

let batchActions = data.searchBarActions;

const Students = ()=> {	
  const size = WindowResize();
  const {broadcast} = React.useContext(Broadcast);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [formContent, setFormContent] = React.useState(<React.Fragment/>);
  let [search, setSearch] = React.useState(null);

  const loggedInUser = broadcast.get("loggedInUser");
  const onTableAction = (actionName, data)=>{
  		tableAction[actionName](data);
  }


const onSearch = (item)=>{
	setSearch(item);
}


const tableAction = {
	assignSessions: (data)=>{
		broadcast.delete("assign-sessions-error");   	
	   setFormContent(
		 	<AssignSessions 
		 		title="Assign Student Session" 
		 		onAction={studentAction.assignSessions} 
		 		onCancel={studentAction.cancel} 
		 		students={data}
		 		loadingChannel="assign-sessions-loading"
		 		errorChannel="assign-sessions-error"
		 	>
		 	</AssignSessions>
		 );
		 setOpenDialog(true);
	},
	unassignSessions: (data)=>{
		broadcast.delete("unassign-sessions-error");   	
	   setFormContent(
		 	<AssignSessions 
		 		title="Unassign Student Session" 
		 		onAction={studentAction.unassignSessions} 
		 		onCancel={studentAction.cancel} 
		 		students={data}
		 		loadingChannel="unassign-sessions-loading"
		 		errorChannel="unassign-sessions-error"
		 	>
		 	</AssignSessions>
		 );
		 setOpenDialog(true);
	},
  	studentSession:(data)=>{
	   broadcast.delete("student-session-error");   	
	   setFormContent(
		 	<StudentSession 
		 		title="Create Student Session" 
		 		onAction={studentAction.studentSession} 
		 		onCancel={studentAction.cancel}
		 		data={{instructorEmail: data[0].instructorEmail}} 
		 		students={data}
		 		loadingChannel="student-session-loading"
		 		errorChannel="student-session-error"
		 	>
		 	</StudentSession>
		 );
		 setOpenDialog(true);
	  },

	   missedAttendance: (data)=>{
   		 broadcast.delete("missed-attendance-error");   	
		 setFormContent(
		 	<Confirmation 
		 		title="Missed Attendance Notification" 
		 		onSubmit={studentAction.sendMissedAttendance} 
		 		onCancel={studentAction.cancel} 
		 		data={data}
		 		loadingChannel="missed-attendance-loading"
		 		errorChannel="missed-attendance-error"
		 	>
		 		<div>Would you like to send notification to the parents of selected students?</div>

		 	</Confirmation>
		 );
		 setOpenDialog(true);
	  },

	  onEditStudentForm: (data)=>{
	  	let user = {email: data.email};
	  	broadcast.delete("student-error");   	
	  	setFormContent(<StudentDetail onAction={studentAction.updateStudent}  user={user} data={data} onCancel={studentAction.cancel}/>);
	  	setOpenDialog(true);
	  },

	    deleteStudents: async (data)=>{
		     broadcast.delete("delete-students-error");   	
			 setFormContent(
			 	<Confirmation 
			 		title="Delete Students" 
			 		onSubmit={studentAction.deleteStudents} 
			 		onCancel={studentAction.cancel} 
			 		data={data}
			 		loadingChannel="delete-students-loading"
			 		errorChannel="delete-students-error"
			 		confirmString="DELETE"
			 	>
			 		<div>Are you sure you want to delete the selected students?</div>

			 	</Confirmation>
			 );
			 setOpenDialog(true);
	    },
   		viewSessionDetail: (data)=>{
   			let tmp = {...data};
   			Object.keys(data.sessionFeedback).forEach(item=>tmp[item]=data.sessionFeedback[item]);
   			delete tmp.sessionFeedback;
   			broadcast.delete("student-session-error");   	
		   	setFormContent(
			 	<StudentSession 
			 		title="Student Session" 
			 		onAction={studentAction.studentSession} 
			 		onCancel={studentAction.cancel} 
			 		students={[]}
			 		data = {tmp}
			 		loadingChannel="student-session-loading"
			 		errorChannel="student-session-error"
			 		readOnly={true}
			 	>
			 	</StudentSession>
			 );
			 setOpenDialog(true);
   		}
   }

   const setConfirmationContent = (title, children, onSubmit, onCancel, data=[], loadingChannel="", errorChannel="")=>{
   		setFormContent(
		 	<Confirmation 
		 		title={title}
		 		onSubmit={onSubmit} 
		 		onCancel={onCancel} 
		 		data={data}
		 		loadingChannel={loadingChannel}
		 		errorChannel={errorChannel}
		 	>
		 		<div>{children}</div>
		 	</Confirmation>
		);
   }

   const onDialogClose = ()=>{
   		setFormContent(<React.Fragment/>)
   		setOpenDialog(false);
   }

   const studentAction = {
   		sendMissedAttendance: async(data)=>{   			
   			broadcast.delete("missed-attendance-loading");
   			broadcast.delete("missed-attendance-error");   	
   			broadcast.set("missed-attendance-loading", true);
   			data = data.map(({studentName, email, instructorName, instructorEmail, studentEmail, course, level, _id})=>({studentName, email, instructorName, instructorEmail, studentEmail, course, level, _id}));
   			let res = await studentResource.post(null, data, 'absentNotification');
     		broadcast.set("missed-attendance-loading", false);
   			if(res.error)
   			 	return broadcast.set("missed-attendance-error", {message: res.error, type:"error"});
   			//onDialogClose();
   			setConfirmationContent("", "Notification has been sent to parents of the selected students", null, onDialogClose);
   			return broadcast.set("refreshStudentTable", Date.now());
   			
   		},
   		deleteStudents: async(data)=>{
   			broadcast.delete("delete-students-loading");
   			broadcast.delete("delete-students-error");   	
   			broadcast.set("delete-students-loading", true);
   			let res = await studentResource.post(null, {_id: data.map(item=>item._id)}, "deleteStudents");
   			broadcast.set("delete-students-loading", false);
   			if(res.error)
   			 	return broadcast.set("delete-students-error", {message: res.error, type:"error"});
   			//onDialogClose();
   			setConfirmationContent("", "Students have been deleted", null, onDialogClose);
   			broadcast.set("refreshStudentTable", Date.now());
   			return broadcast.set("cancelSelectedStutents", Date.now());
   			
   		},
   		studentSession: async(data)=>{
   			broadcast.delete("student-session-loading");
   			broadcast.delete("student-session-error");   	
   			broadcast.set("student-session-loading", true);
   			let res = await sessionResource.post(null, data);
   	   		broadcast.set("student-session-loading", false);
   			if(res.error)
   			 	return broadcast.set("student-session-error", {message: res.error, type:"error"});
   			//onDialogClose();
   			setConfirmationContent("", "Student session has been created", null, onDialogClose);

   			return broadcast.set("refreshStudentTable", Date.now());
   			
   		},
   		assignSessions: async(data)=>{
   			broadcast.delete("assign-sessions-loading");
   			broadcast.delete("assign-sessions-error");   	
   			broadcast.set("assign-sessions-loading", true);
   			let res = await sessionResource.put(null, data.students, "assignSessions?_id=" + data.sessions);
   	   		broadcast.set("assign-sessions-loading", false);
   			if(res.error)
   			 	return broadcast.set("assign-sessions-error", {message: res.error, type:"error"});
   			//onDialogClose();
   			setConfirmationContent("", "Session has been assigned to the seleceted students", null, onDialogClose);

   			return broadcast.set("refreshStudentTable", Date.now());	
   		},
   		unassignSessions: async(data)=>{
   			broadcast.delete("unassign-sessions-loading");
   			broadcast.delete("unassign-sessions-error");   	
   			broadcast.set("unassign-sessions-loading", true);
   			let res = await sessionResource.put(null, data.students, "unassignSessions?_id=" + data.sessions);
   	   		broadcast.set("unassign-sessions-loading", false);
   			if(res.error)
   			 	return broadcast.set("unassign-sessions-error", {message: res.error, type:"error"});
   			//onDialogClose();
   			setConfirmationContent("", "Session has been unassigned from the seleceted students", null, onDialogClose);

   			return broadcast.set("refreshStudentTable", Date.now());
   			
   		},
   		updateStudent: async (data, callback)=>{
   			broadcast.delete("student-error");   	
   			let res = await studentResource.put(null, data, data._id);
   			callback();
   			if(res.error) return broadcast.set("student-error", {message: res.error, type:"error"});
   			//onDialogClose();
   			setConfirmationContent("", "Student has been updated successfuly", null, onDialogClose);

   			return broadcast.set("refreshStudentTable", Date.now());
   		},

   		cancel: ()=>{
   			onDialogClose();
   		}

   }

   const renderStudentRow = ()=>{
   		if(loggedInUser.userRole==2) return <StudentRow/>;
   		if(loggedInUser.userRole==1) return <AdminStudentRow/>;
   		return <BasicStudentRow/>;
   }

   React.useEffect(()=>{
   			tableHeaders = tableHeaders.filter(item=>item.roles.indexOf(loggedInUser.userRole)>-1);
   			batchActions = batchActions.filter(item=>item.roles.indexOf(loggedInUser.userRole)>-1);
   			if(loggedInUser.userRole==0){
   				hasSelect = false;
   				data.hasSearch = false;
   			}
   		}, []);

  return (
  	<div className="page-padding">
    	<React.Fragment>
	    	{(size.width<=850)?(
		  		<Dialog
		  			disableEnforceFocus
		  			disableEscapeKeyDown
		  			disableBackdropClick 
			  		className="form-dialog"
			        disableEscapeKeyDown={true}
			        open={openDialog}
			        onClose={evt=>onDialogClose()}
			        TransitionComponent={Transition}
			      >	
			      	<div className="form-container row-flex center-flex" style={{position: "relative", height: "100%", width:"100%"}}>
			      		<div className="close-form">
				          <IconButton aria-label="expand row" size="small" onClick={()=>onDialogClose()}>
				              <Icon>clear</Icon>
				          </IconButton>
				        </div>
			      		<div style={{width:  size.width>=850?'70%':'90%'}}>{formContent}</div>
			      	</div>
			    </Dialog>):(
			    <Drawer 
			    	disableEnforceFocus
			    	disableEscapeKeyDown
			    	disableBackdropClick 
			     	anchor='right' 
			     	open={openDialog} 
			     	onClose={evt=>onDialogClose()}
			      >
			      	<div className="form-container row-flex center-flex" style={{height: "100%", width: "650px", position: "relative",}} >
			      		<div className="close-form">
				          <IconButton aria-label="expand row" size="small" onClick={()=>onDialogClose()}>
				              <Icon>clear</Icon>
				          </IconButton>
				        </div>
			      		<div style={{width:  size.width>=850?'70%':'90%'}}>{formContent}</div>
			      	</div>
		        </Drawer>)
		  	}
	    </React.Fragment>


	    <SDKTable 
	    	rowId={"_id"}
	    	tableHeaders={tableHeaders} 
	    	onRowAction={onTableAction}
	    	refreshChannel={"refreshStudentTable"}
	    	cancelSelectedChannel= {"cancelSelectedStutents"}
	    	defaultOrder={{name:'studentName', value:'asc'}}
	    	hasSelect={hasSelect}
	    	inlineAction={1}
	    	resource={studentResource}
	    	initialFilter={loggedInUser.userRole?{'isActive':true}:null}
	    	batchActions = {batchActions}
	    	hasSearch={data.hasSearch}
	    	onTableAction={onTableAction}
	    >
	    	{renderStudentRow()}
	    </SDKTable>
    </div>
    
  );
}

export default Students;
