import React, {useContext} from 'react';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import PageAlert from './../sdk/pageAlert/pageAlert';
import { Broadcast } from './../sdk/broadcast';
import Resource from './../resources/resource';

import ResetPassword from './resetPassword';
import { form, style } from './config';

function UpdatePassword(props) {
  const {broadcast} = React.useContext(Broadcast);
  const [_form, _setForm] = React.useState({...form, oldPassword: {label: "Old Password", is_required: true}});
  
  const handleChange = (id, value)=>{
    _form.onChange(id, value);
    if(id.toLowerCase() =="password" && _form['password'].value==_form['oldPassword'].value && !_form["password"].error)  _form["password"].error = "New password must not be reused";
    else if( id.toLowerCase().indexOf("password")>-1 && 
      !_form["password"].error && 
      !_form["retypePassword"].error && 
      _form["password"].value != _form["retypePassword"].value) 
      _form["retypePassword"].error = "Password doesn't match"
    _setForm({..._form});
  }

  const onSubmit = async ()=>{
    const resource = new Resource("oauth2/updatePassword");
    const values = _form.values();
    const loggedInUser = broadcast.get("loggedInUser");
    let res = await resource.post(null, {newPassword:  values.password, password: values.oldPassword, email: loggedInUser.email});
    if(res.error) return broadcast.set("reset_password", {type:"error", message: res.error});
    return onCancel();
  } 

  const onCancel = ()=>{
    broadcast.set("reset_password", {type:"error", message: ""});
    _form.clear();
    _setForm({..._form});
    if(props.onCancel) props.onCancel();
  }

  return (
    <React.Fragment>
      <form noValidate autoComplete="off" className="row-flex center-flex">
        
        <div style={{width:'100%'}} className="account column-flex form-container">
            <div>
              <TextField
                style={{width:'100%'}}
                required = {_form.oldPassword.is_required}
                error = {_form.oldPassword.error?true:false}
                id="oldPassword"
                value={_form.oldPassword.value || _form.oldPassword.default||""}
                label={_form.oldPassword.label}
                helperText={_form.oldPassword.error||""}
                variant="outlined"
                onChange={(event)=>handleChange('oldPassword', event.target.value)}
                type="password"
              />
            </div> 

            <ResetPassword form={_form} handleChange={handleChange}></ResetPassword>
        </div>

         <div style={{width:'100%', marginTop: '10px'}} className="row-flex center-flex">
              <React.Fragment>
                <Button  style={{width: "120px"}}  onClick={onCancel}>Cancel </Button> 
                <div style={{width: "20px"}}></div>
                <Button variant="contained" 
                        disabled = {_form.hasError()}
                        style={{color:'white', minWidth: '120px', backgroundColor: _form.hasError()?'#e5e5e5':'#629bf1'}}
                        onClick={onSubmit}>
                        Submit
                </Button>
              </React.Fragment>
          </div>
      </form>
      <PageAlert id ="reset_password" className="reset_password" />
    </React.Fragment>
  );
}

export default UpdatePassword;
