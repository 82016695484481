import React, {useEffect} from 'react';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import CircularProgress from '@material-ui/core/CircularProgress';
import Information from "./../sdk/information/information";
import Icon from '@material-ui/core/Icon';
import { Button } from '@material-ui/core';

import UserResource from './../resources/userResource';

const userResource = new UserResource();

const UserRow = (props)=>{
	const { row } = props;
    const [open, setOpen] = React.useState(false);
	const [loadingContent, setLoadingContent] = React.useState(false);   
	const [subTable, setSubtable] = React.useState(null);
    const onCollage = async()=>{
    	setOpen(!open);
    	if(!open){
    	   	if(!subTable || subTable.error){
	    		setLoadingContent(true);
	    		let res = await userResource.studentSummary(row.email);
	    		setSubtable(res);
	    		setLoadingContent(false);
	    	}
    	}
    	return <React.Fragment/>;
    }

    const getSubTable = ()=>{
    	if(!subTable) return <React.Fragment/>;

    	if(subTable.error) return getError(subTable.error);

    	return (
    			<Table size="small" aria-label="purchases">
		            <TableHead>
		                <TableRow>
		                	<TableCell />
		                   <TableCell>Level</TableCell>
		                   <TableCell>Student Name</TableCell>
		                   <TableCell>Instructor</TableCell>
		                   <TableCell>Session Time</TableCell>
		                 </TableRow>
		            </TableHead>
		            <TableBody>
		                {subTable.map((student, index) => (
		                    <TableRow key={index}>
		                      	<TableCell>
						          	<IconButton className="table-action" aria-label="expand row" size="small" onClick={()=>props.onAction('onDeleteStudentForm', {...student, email: row.email})}>
						            	<DeleteForeverIcon/>
						          	</IconButton>
						 
						           <IconButton className="table-action" aria-label="expand row" size="small" onClick={()=>props.onAction('onEditStudentForm', {...student, email: row.email})}>
						            	<EditIcon/>
						          	</IconButton>
						        </TableCell>
		                      	<TableCell scope="row">{student.level}</TableCell>
		                      	<TableCell scope="row">{student.studentName}</TableCell>
		                      	<TableCell scope="row">{student.instructorName}</TableCell>
		                      	<TableCell scope="row">{student.sessionDateTime}</TableCell>
		                    </TableRow>
		                ))}
		            </TableBody>
		        </Table>
    	)
    }

    const getError = (error)=>{
    	return <div style={{color: "red",width: "100%", position: "absolute", textAlign: "center", marginTop: "20px"}}>{"Error: " + error}</div>
    }

    return (
    	<React.Fragment>
    		<TableRow className="user-row">
		        <TableCell>
		          <React.Fragment>	
			        {
			        	row.userRole==0?
			          	(<IconButton aria-label="expand row" size="small" onClick={() =>onCollage()}>
			            	{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
			          	</IconButton>):
			          	<IconButton className="iconButtonHolder" disabled/>
			      	}

		          </React.Fragment>
		           <IconButton className="table-action" aria-label="expand row" size="small" onClick={()=>props.onAction('onEditUserForm', row)}>
		            	<EditIcon/>
		          	</IconButton>
		        </TableCell>
		        <TableCell >{row.name}</TableCell>
		        <TableCell >{row.email}</TableCell>
		        <TableCell >{row.phoneNumber}</TableCell>
		        <TableCell >{row.userRole==2?"Admin":row.userRole==1?"Instructor":"Basic User"}</TableCell>
		        <TableCell >{row.createdDate}</TableCell>
		    </TableRow>

		    <React.Fragment>
		    	{  
		    		row.userRole==0?
				    (<TableRow className="sub-table">
				        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
				          <Collapse in={open} timeout="auto" unmountOnExit>
				            <Box margin={1}>
				              <div className="row-flex">
									<Typography variant="h6" gutterBottom component="div">
						                Students
						            </Typography>
						              
									<div className="free-space"/>
									<Button className="table-action" onClick={()=>props.onAction('onNewStudentForm', row)}><Icon>add</Icon>New Student</Button>

							  </div>
				              <React.Fragment>
				              	{loadingContent?<div className="row-flex center-flex"><CircularProgress/></div>:<React.Fragment/>}
				              </React.Fragment>	

				              <React.Fragment>
				              	{getSubTable()}
				              </React.Fragment>
				            </Box>
				          </Collapse>
				        </TableCell>
				    </TableRow>):
				    <React.Fragment/>
				}
		    </React.Fragment>
    	</React.Fragment>
    );
}

export default UserRow;
