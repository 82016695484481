import React, {useEffect} from 'react';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import TableRow from '@material-ui/core/TableRow';
import VisibilityIcon from '@material-ui/icons/Visibility';

const StudentRow = (props)=>{
    return (
    	<React.Fragment>
    		<TableRow  key={props.index}>
    			<React.Fragment>{(props.fields[0]=="instructorName")?<TableCell>{props.row.instructorName||""}</TableCell>:<React.Fragment/>}</React.Fragment>
		        <TableCell>{props.row.studentName || ""}</TableCell>
		        <TableCell>{props.row.course || ""}</TableCell>
		        <TableCell>{props.row.level || ""}</TableCell>
		        <TableCell>{props.row.sessionDateTime || ""}</TableCell>
		        <TableCell>{props.metaData.students.filter(item=>(item._id==props.id && item.absent)).length?"Absent":"Attended"}</TableCell>
		    </TableRow>	    		
		</React.Fragment>
    );
}

export default StudentRow;
