const data ={
		headers:[
			{id: "course", label: "Course", type:'string' },
			{id: "date", label: "Date", type:'string' },
			{id: "sessionName", label: "Session Name",  type:'string' },
			{id: "instructorEmail", label: "Instructor Email",  type:'string' },

			// {id: "homeworkDetail", label: "Homework Detail", type:'string' },
			// {id: "sessionFeedback", label: "Session Feedback", type:'string' },
		],
		studentHeaders:[
			{id: "studentName", label: "Student Name",  type:'string' },
			{id: "course", label: "Course",  type:'string' },
			{id: "level", label: "Level", type:'number' },
			{id: "sessionDateTime", label: "Session Time", type:'string' },
			{id: "attendantStatus", label: "Attendant Status", noSort: true, noSearch: true },
		]
	}
export { data};
