import React, {useEffect} from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';

import Paper from '@material-ui/core/Paper';
import { Broadcast } from "./../broadcast";
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import { Button,IconButton, Icon } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Fab from '@material-ui/core/Fab';

import TableSortLabel from '@material-ui/core/TableSortLabel';

import Tooltip from '@material-ui/core/Tooltip';

import UtilityBar from './../utilityBar/utilityBar';


const useStyles = makeStyles({
  container: {
    minHeight: ({minHeight})=>minHeight,
   
  }
});



const SDKTable = (props)=>{
	let {
			metaData,
			rowId,
			tableHeaders, 
			resource,
			onRowAction, 
			refreshChannel, 
			cancelSelectedChannel,
			hasSelect,
			defaultOrder={},
			searchItem,
			batchActions=[],
			hasSearch,
			onTableAction,
			inlineAction,
			initialFilter={},
			minHeight=500,
			children
		} = props;

    const classes = useStyles({minHeight});
	const {broadcast} = React.useContext(Broadcast);
	const [loadingContent, setLoadingContent] = React.useState(false);   
	const [table, setTable] = React.useState([]);
	let [selectedRows, setSelectedRows] = React.useState({});
	let [count, setCount] = React.useState(null);
	let [page, setPage] = React.useState(0);
	let [limit, setLimit] = React.useState(10);
	let [order, setOrder] = React.useState({id: defaultOrder.name, value:defaultOrder.value});
	let [orderBy, setOrderBy] = React.useState(`${defaultOrder.name}:${defaultOrder.value=='desc'?'>':'<'}`);
	let [search, setSearch] = React.useState(null);
	let [error, setError] = React.useState("");
	
	let rows = Object.values(selectedRows);
	const onRowSelect = (id, index, selected)=>{
		if(selected) selectedRows[id] = table[index];
		else delete selectedRows[id];
		setSelectedRows({...selectedRows});
	}


	const getRow = (row, index)=>{
		const childRef = React.createRef();
		if(selectedRows[row[rowId]]) setTimeout(function() {
			if(childRef.current)
			childRef.current.toggleSelect(true);
		}, 500);

		return React.cloneElement(children||<SDKRow/>, { 
			fields: tableHeaders.map(item=>item.id), 
			row, 
			metaData,
			index, 
			id: row[rowId], 
			onAction:onRowAction, 
			onRowSelect, 
			hasSelect, 
			cancelSelectedChannel,
			ref: childRef,
			isSelected: selectedRows[row[rowId]]?true:false}
		);
	}

	const getTableData = async()=>{
		setTable([]);
		setError("");
		setLoadingContent(true);
		let body = {...initialFilter};
		if(search) body = {...body, ...search};
		if(count===null){
			const total = await resource.get(null, body, 'count');
			setCount(total.count);
			if(!total.count){
				setTable([]);
				setLoadingContent(false);
				return;
			}
		}
		body.limit = parseInt(limit);
		body.offset = (parseInt(page) + 1);
		if(orderBy) body.orderBy = orderBy;
		
		const res = await resource.get(null, body);
		if(res.error) 
			setError(res.error);
		else
			setTable(res);
		setLoadingContent(false);
	}

	const handleChangePage = (evt, newPage)=>{
		page = newPage;
		setPage(page);
		getTableData();
	}

	const handleChangeRowsPerPage = (event) => {
		limit = event.target.value;
	    setLimit(parseInt(limit, 10));
	    page = 0;
	    setPage(page);
	    getTableData();
	}

	const resetTable=()=>{
		page = 0;
		limit = 10;
		count = null;
		setLimit(limit);
		setPage(page);
		setCount(count);
		setSelectedRows({});
	}

	const onSortAction = (id) => {
		if(id==order.id) order.value = order.value=="asc"?"desc":"asc";
		else order = {id: id, value: "asc"};
		
		page = 0;
		setOrder({...order});
		orderBy = `${order.id}:${(order.value=="asc")?"<":">"}`;
		setOrderBy(orderBy);
		setPage(page);
	    getTableData();

	}

	const onSearch = (item)=>{
		search = getSearchValue(item);
		setSearch(search);
		page = 0;
		setPage(page);
		count = null;
		setCount(count);
	    getTableData();
	}

	const getSearchValue = (items)=>{
		if(!items.length) return "";
		let res = {};
		for(let item of items){
			if(item.value==="" || item.value===null) continue;
			res[item.id] = item.value;
		}
		let count = 0;
		for (let i=0; i<tableHeaders.length;i++){
			if(res.hasOwnProperty(tableHeaders[i].id)){
				if(tableHeaders[i].type=='string')
				 	res[tableHeaders[i].id] = ('~'+res[tableHeaders[i].id]);
				count++;
			}
		}
		if(!count) return "";
		return res;
	}

	const onCancelSelected = ()=>{
		//setSelectedRows({});
		broadcast.set(cancelSelectedChannel || "sdkTableCancelSelected", Date.now());
	}

	React.useEffect(()=>{
		getTableData();
	}, [broadcast.get(refreshChannel||'sdkTableRefresh'), search]);

	React.useEffect(()=>{
		setSelectedRows({});
	}, [broadcast.get(cancelSelectedChannel||'sdkTableCancelSelected')]);

	React.useEffect(()=>{
		if(searchItem){
			let search = getSearchValue(searchItem);
			setSearch(search);
		}else
			setSearch(null);
	}, [searchItem]);

	const renderBatchActions = ()=>{
		const hasSelectedRows = rows.length;
		return batchActions.map(item=>{
			if(!item.isBatch || hasSelectedRows) 
				return (<React.Fragment key={item.icon}>
							<Tooltip title={item.label} >
									{item.fabIcon?
									(<Fab size="small" onClick={()=>onTableAction(item.id, rows)} className="table-action" style={{marginLeft:"10px"}}><Icon>{item.icon}</Icon></Fab>):
									(<IconButton onClick={()=>onTableAction(item.id, rows)} className="table-action"><Icon>{item.icon}</Icon></IconButton>)}
							</Tooltip>
						</React.Fragment>);
			return <React.Fragment key={item.icon}/>;
		});
	}

	return (
		<div style={{position:"relative"}}>
			<div style={{marginBottom: "5px"}}>
			    <UtilityBar 
			    	onSearch={onSearch} 
			    	hasSearchBar={hasSearch} 
			    	searchFields={tableHeaders.filter(item=>(!item.noSearch))} 
			    	selected={rows.length} 
			    	onCancelSelected={onCancelSelected}>
			    		{renderBatchActions()}
			    </UtilityBar>
		    </div>
			<div>{loadingContent?<LinearProgress/>:<React.Fragment/>}</div>

			<TableContainer component={Paper} className={classes.container} style={{paddingBottom:"60px"}}>
		      <Table aria-label="collapsible table" style={{ minWidth: 1200 }} size='medium'>
		        <TableHead>
		          <TableRow>
		          	<React.Fragment>
		            	{hasSelect||inlineAction?<TableCell style={{width: "100px"}} />:<React.Fragment/>}
		            </React.Fragment>
		            <React.Fragment>
		            	{
		            		tableHeaders.filter(item=>item.id&&!item.hidden).map(item=>(
		            			<TableCell key={item.id} sortDirection={order.id === item.id ? order.value : false}>
		            				{item.noSort?<React.Fragment>{item.label}</React.Fragment>:
		            					<TableSortLabel
							              active={order.id === item.id}
							              direction={order.id === item.id ? order.value : 'asc'}
							              onClick={()=>onSortAction(item.id)}
							            >
							              {item.label}
							
							        	</TableSortLabel>
		            				}
		            			</TableCell>
		            		))
		            	}
		            </React.Fragment>
		          </TableRow>
		        </TableHead>  
		        <TableBody>
		          {table.map((row, index) => (<React.Fragment key={index}>{getRow(row, index)}</React.Fragment>))}
		        </TableBody>
		      </Table>
		       <div style={{color: "red",width: "100%", position: "absolute", textAlign: "center", marginTop: "20px"}}>{error}</div>
		       
		      <div style={{position: "absolute", width: "100%", bottom: "15px"}}>
		    	<TablePagination
		          rowsPerPageOptions={[10, 50, 100]}
		          component="Paper"
		          count={count || 0}
		          rowsPerPage={limit}
		          page={page}
		          onPageChange={handleChangePage}
		          onRowsPerPageChange={handleChangeRowsPerPage}
		        />
		       </div>
		    </TableContainer>
	    </div>
	);
}


const SDKRow = React.forwardRef((props, ref)=>{
	let {fields, row, id,  index, hasSelect, isSelected, cancelSelectedChannel} = props;
	const [selected, setSelected] = React.useState(isSelected);
	const {broadcast} = React.useContext(Broadcast);

	const handleClick = ()=>{
    	props.onRowSelect(id, index, !selected);
    	setSelected(!selected);
    }

     React.useEffect(()=>{
    	setSelected(false);
    }, [broadcast.get(cancelSelectedChannel || "sdkTableCancelSelected")]);

    React.useImperativeHandle(ref, ()=>({
    	toggleSelect: (val)=>setSelected(val)
    }));

	return (
		<TableRow  sx ={{ '&:last-child td, &:last-child th': { border: 0 } }}>
				<React.Fragment>
					{hasSelect?
						(<TableCell>
							<Checkbox checked={selected} onClick={(event) => handleClick()} />
			        	</TableCell>):<React.Fragment/>}
		        </React.Fragment>
		        <React.Fragment>
		        	{fields.map((field, index)=>(
		        		<TableCell key={index}>{typeof(row[field])=='object'? JSON.stringify(row[field]):row[field]}</TableCell>
		        	))}
		        </React.Fragment>
		     
		 </TableRow>
	);
})

export default SDKTable;
