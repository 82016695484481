import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Broadcast } from './../broadcast';

let interval = null;


export default function SDKAutoComplete({resource, label, id, displayKey, onChange, multiple, style, errorChannel}) {
  const [options, setOptions] = React.useState([]);
  let [searchValue, setSearchValue] = React.useState([]);
  const [value, setValue] = React.useState(multiple?[]:null);
  const {broadcast} = React.useContext(Broadcast);

  const getSearchData = async()=>{
    let options = [];
    let values = {};
    if(value) values = (multiple?value:[value])
                      .reduce((res, item)=>{
                         res[item[id]] = item;
                         return res; 
                      }, {});
    if(searchValue){
      let res = await resource.get(null, {[displayKey]:`~${searchValue}`});
      if(res.error) broadcast.set(errorChannel||"sdk-auto-complete-error", res.error);
      else {
        options = res;
        if(value) options = res.filter((item)=>{
                                if(values[item[id]]){
                                  delete values[item[id]];
                                  return false;
                                }
                                return true;
                              });
      }
    }
    setOptions(options);
  }

  const onSearchValue = (val)=>{
    searchValue = val;
    setSearchValue(searchValue);
    if(interval){
      clearInterval(interval);
      interval = null;
    }
    interval = setInterval(()=>{
        clearInterval(interval);
        interval = null;
        getSearchData();
    },1000);
  }

  const onSelected = (val)=>{
    setValue(val);
    onChange(val);
  }

  return (
    <Autocomplete
      multiple ={multiple}
      id="sdk-auto-complete"
      options={options}
      getOptionLabel={(option) => option[displayKey]}
      style={style||{}}
      onInputChange={(evt, val)=>onSearchValue(val)}
      onChange={(evt, val)=>onSelected(val)}
      onOpen={()=>setOptions([])}
      renderInput={(params) => <TextField {...params} label={label} variant="outlined" />}
    />
  );
}