import React, {useContext, useEffect, useState} from 'react';
import './inline.css';
import { config } from './config';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import { Button } from '@material-ui/core';
import { useLocation, Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import { Broadcast } from "./../sdk/broadcast";
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 75,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
   toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
}));

  let menus = config();


const Inline =(props)=>{
  console.log("inline");
  const classes = useStyles();
  const theme = useTheme();

  const [open, setOpen] = React.useState(true);

  const handleToggle = () => {
    setOpen(!open);
  };
  const {broadcast} = useContext(Broadcast);
  const loggedInUser = broadcast.get("loggedInUser");
  if(!loggedInUser) menus = [];
  else menus = menus.filter(item=>(loggedInUser.userRole>=item.role));
  
  const classNameforItem = (menu)=>{
    let key = (menu.key||"").replace(/\//g,"");
    document.querySelectorAll(".menu-item").forEach(item=>item.classList.remove('active-page'));
    document.querySelectorAll(`.menu-item.${key}`).forEach(item=>item.classList.add('active-page'));
    broadcast.set("activePage", menu.path);
    if(!menu.path)
      broadcast.set(menu.key, true);
  }

  useEffect(()=>{
      let key = (broadcast.get('activePage')||"").replace(/\//g,"");
      document.querySelectorAll(".menu-item").forEach(item=>item.classList.remove('active-page'));
      if(key) 
        setTimeout(function() {
          document.querySelectorAll(`.menu-item.${key}`).forEach(item=>item.classList.add('active-page'));  
        }, 100);
  },[ broadcast.get("activePage")])

   useEffect(()=>{
      let key = window.location.pathname;
      if(!key) key = "profile";
      else key = key.split("/")[1]; 
      document.querySelectorAll(".menu-item").forEach(item=>item.classList.remove('active-page'));
      if(key) 
        setTimeout(function() {
          document.querySelectorAll(`.menu-item.${key}`).forEach(item=>item.classList.add('active-page'));  
        }, 100);
  },[])

  const getLink =(item)=>{
    return (
      <ListItem button key={item.key}>
        {open?(<Icon style={{color:"white", marginTop:"-12px"}} className="menu-item-icon">{item.icon}</Icon>):<React.Fragment/>}
        <Link key={item.key} className={"menu-item " + item.key} to={item.path} onClick={evt=>classNameforItem(item)} style={{minWidth: "40px", minHeight:"40px", width: "100%"}}>
                  {(!open)?(<Icon style={{color:"white"}} className="menu-item-icon-sm">{item.icon}</Icon>):<React.Fragment/>}
                  <span className="menu-item-title" data-aos="flip-up" data-aos-delay="500" style={{display: open?'initial':'none'}}>{item.title}</span>
                  <span className="menu-item-underline"></span>
        </Link>
      </ListItem>
    );
  }

  const content = (
    <div>
      <List>
         {menus.map(item=>getLink(item))}
      </List>
    </div>
  );

  return (
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleToggle}>
            {open? <img src={require("./../assets/logo.png")} style={{height:"56px"}} /> : <img src={require("./../assets/logo_sm.png")} style={{height:"20px"}} />}
          </IconButton>
        </div>
        <Divider />
        <div className="inline-menu">
          {content}  
        </div>
     </Drawer>
  );
}

export default Inline;
