import React, {useContext, useEffect} from 'react';
import {BrowserRouter as Router, Link} from "react-router-dom"; 
import { HashLink } from 'react-router-hash-link';

import {Broadcast, BroadcastProvider} from './sdk/broadcast';

import Inline from './routes/inline';
import Fullscreen from './routes/fullscreen';

import Login from './login/login'; 

import { config } from './routes/config';
 
import AppRoutes from './routes/app-routes'; 
import Loading from './sdk/loading/loading';

import BreadCrumbs from './sdk/breadCrumbs/breadCrumbs';

import ExtensionIcon from '@material-ui/icons/Extension';

import { AppBar, Toolbar, Button } from '@material-ui/core';

import Authentication from './authentication/authentication';

import Information  from './sdk/information/information';

import ProfileMenu from './profileMenu';

import './App.css';

import {data, style } from './config';

const labelsObject = config().reduce((res, item)=>{
  res[item.key] = item.title
  return res;
},{})

function App() {
  const {broadcast} = useContext(Broadcast);

  useEffect(()=>{
  },[]);

  return (
    <div className="App">
      <Router>
        <BroadcastProvider>
          <Authentication>
            <div className="row-flex no-wrap" >
              <div className="inline"><Inline/></div>
              <div className="free-space row-flex" style={{minHeight: "100vh", overflow:"hidden"}}>
                <div style={{width:'100%'}} className="column-flex">
                  <AppBar position="relative" className="app-toolbar" >
                    <Toolbar>
                       <div className="fullscreen"><Fullscreen/></div>
                       <BreadCrumbs labelsObject={labelsObject}/>
                      <div className="free-space"></div>
                      <ProfileMenu/>
                    </Toolbar>
                    <Loading/>
                  </AppBar>
                  <div className="app-body">
                    <AppRoutes/>
                  </div>
                  <div className="free-space" />
                  <footer>
                      <Information id="app-footer" style={style}>
                          <div className="row-flex">
                             {(data.footers||[]).map((item, index)=>(
                                <div className="footer-container" key={index}>
                                  <div className="app-subtitle">{item.label}</div>
                                  <br/>
                                  <div>{item.contents.map((content, idx)=>(
                                      <div className="footer-content" key={idx}>
                                        <a href={content.url} className="app-description" target={content.newTab?"_blank":""}>{content.label}</a>
                                      </div>
                                    ))}</div>
                                </div>
                              ))}
                          </div>
                      </Information>
                    </footer>
                  </div>
                </div>
              </div>
            </Authentication>
        </BroadcastProvider>
      </Router>
    </div>
  );
}

export default App;
