import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import PageAlert from './../sdk/pageAlert/pageAlert';
import Loading  from './../sdk/loading/loading';
import { Broadcast } from './../sdk/broadcast';
import SessionResource from './../resources/sessionResource';

import SDKSAutoComplete from './../sdk/sdkAutoComplete/sdkAutoComplete';


const sessionResource = new SessionResource();

function AssignSessions({title, students, data, onAction, onCancel, loadingChannel, errorChannel}) {
  if(!Array.isArray(students)) students = [students];
  let [sessions, setSessions] = React.useState([]);  
  const {broadcast} = React.useContext(Broadcast);
  const loading  = broadcast.get(loadingChannel);

  const loggedInUser = broadcast.get("loggedInUser");
  const handleChange = (value)=>{
    setSessions(value);
  }
  

  const onSubmit = ()=>{
    onAction({sessions: sessions.map(val=>(val._id||val.id)).join(","), students: students.map(val=>val._id||val.id)});
  }

  return (
      <form noValidate autoComplete="off" className="row-flex center-flex">
        <div className="row-flex center-flex" data-aos="flip-down" data-aos-delay="100" data-aos-easing="ease-in-out"><Typography variant="h5">{title}</Typography ></div>
        <div style={{width:'100%'}} >
          <br/>
          <div className="row-flex center-flex" style={{minHeight: "200px", width:'100%'}}>
           <SDKSAutoComplete 
              resource={sessionResource}
              label="Student Sessions"
              id="_id"
              displayKey="sessionName"
              searchKeys="course-sessionName-homeworkDetail-date"
              errorChannel={errorChannel}
              onChange={handleChange}
              style={{ width:'100%'}}
              multiple
           />
          </div>
          <div style={{width:'100%', marginTop: '10px'}} className="row-flex center-flex">
            {
                loading?<React.Fragment/>:
                (<React.Fragment>
                  <Button  style={{width: "120px"}} onClick={()=>onCancel()}>Cancel </Button> 
                  <div style={{width: "20px"}}></div>
                  <Button variant="contained" 
                      disabled = {!sessions.length}
                      style={{color:'white', minWidth: '120px', backgroundColor: !sessions.length?'#e5e5e5':'#629bf1'}}
                      onClick={()=>onSubmit(data)}>
                      Submit
                  </Button>
                </React.Fragment>)
              }
          </div>
        
          <div className="row-flex center-flex" style={{width: "100%", minHeight:"50px", marginTop:"50px"}}><PageAlert id={errorChannel} className={errorChannel} /></div>
          <div className="row-flex center-flex" style={{width: "100%", minHeight:"50px", marginTop:"50px"}}><Loading id={loadingChannel} className={loadingChannel} loadingType="circular"/></div>
        </div>
      </form>
  );
}

export default AssignSessions;
