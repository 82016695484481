export { data, style };
const data ={
		headers:[
			{id: "name", label: "Name", type:'string' },
			{id: "email", label: "Email",  type:'string' },
			{id: "phoneNumber", label: "Phone Number", type:'string' },
			{id: "userRole", label: "User Role", type:'number', searchMaps:[{searchPattern:'basic user', value:0}, {searchPattern:'instructor', value:1}, {searchPattern:'admin', value:2}] },
			{id: "createdDate", label: "Created Date",  type:'date' },
		],
		hasSearch:true,
		searchBarActions:[
			{id: "onNewUserForm", label: "New User", icon:'add', fabIcon:true },
		]
	},
	style = {
	
	}