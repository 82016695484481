import React from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import { Broadcast } from './../broadcast';
import Loading  from './../loading/loading';
import PageAlert from './../pageAlert/pageAlert';

function Confirmation({title, confirmString, onCancel, onSubmit, loadingChannel, errorChannel, data, children}) {
  const {broadcast} = React.useContext(Broadcast);
  const [confirm, setConfirm] = React.useState("");
  const loading  = broadcast.get(loadingChannel);
  React.Children.forEach(children, element => {
  if (!React.isValidElement(element)) return

    //do something with source..
  })
  const handleChange = (value)=>{
    setConfirm(value);
  }

  const hasError = ()=>{
    if(!confirmString) return false;
    return confirm!=confirmString;
  }

  return (
      <form noValidate autoComplete="off" className="row-flex center-flex">
        <div className="row-flex center-flex" data-aos="flip-down" data-aos-delay="100" data-aos-easing="ease-in-out"><Typography variant="h5">{title}</Typography ></div>
        <div style={{width:'100%'}} className="confirmation column-flex form-container">
          <br/>
          <div className="column-flex center-flex" style={{minHeight:"150px"}}>{children}</div>
          <div>
            {
              confirmString?(
              <TextField
                style={{width:'100%'}}
                required
                placeholder={'Type "' + confirmString + '" to continue'}
                onChange={(event)=>handleChange(event.target.value)}
              />):<React.Fragment/>
            }
          </div>

          <div style={{width:'100%', marginTop: '10px'}} className="row-flex center-flex">
              {
                loading?<React.Fragment/>:
                (<React.Fragment>
                  <Button  style={{width: "120px"}} onClick={()=>onCancel()}>{onSubmit?"Cancel":"Close"}</Button> 
                  <React.Fragment>
                    {!onSubmit?<React.Fragment/>:
                      <React.Fragment>
                        <div style={{width: "20px"}}></div>
                        <Button variant="contained" 
                            disabled = {hasError()}
                            style={{color:'white', minWidth: '120px', backgroundColor: hasError()?'#e5e5e5':'#629bf1'}}
                            onClick={()=>onSubmit(data)}>
                            Submit
                        </Button>
                      </React.Fragment>
                    }
                  </React.Fragment>

                </React.Fragment>)
              }
                
          </div>
        
          <div className="row-flex center-flex" style={{width: "100%", minHeight:"50px", marginTop:"50px"}}><PageAlert id={errorChannel} className={errorChannel} /></div>
          <div className="row-flex center-flex" style={{width: "100%", minHeight:"50px", marginTop:"50px"}}><Loading id={loadingChannel} className={loadingChannel} loadingType="circular"/></div>
        </div>
      </form>
  );
}

export default Confirmation;
